export default {
  en: {
    notFound: {
      title : 'Oops! Something went wrong.',
      but : 'but',
      backTo : 'Go to Homepage',

    },
  },
  th: {
    notFound: {
      title : 'ขออภัย! มีข้อผิดพลาดเกิดขึ้น',
      but : 'แต่',
      backTo : 'กลับไปที่หน้าแรก',
    },
  },
};
