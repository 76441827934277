





























































































































































import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import FileUploader from '@/components/FileUploader.vue';
import careerAvailable from '../data/careerAvailable';

class CareerForm {
  public fullName: string;
  public email: string;
  public position: string;
  public numberPhone: string;
  public website: string;
  public notes: string;
  public resume: File;
  public otherUpload: File;
  constructor() {
    this.fullName = '';
    this.email = '';
    this.position = '';
    this.numberPhone = '';
    this.website = '';
    this.notes = '';
    this.resume = null as any;
    this.otherUpload = null as any;
  }
}

export default Vue.extend({
  data(): {
    careerForm: CareerForm,
    position: string,
    error: number,
    submitting: boolean,
    submitSuccess: boolean,
  } {
    return {
      position:  '',
      careerForm: new CareerForm(),
      error: 0,
      submitting: false,
      submitSuccess : false,
    };
  },
  computed: {
  },
  methods: {
    submit() {
      this.submitting = true;
      this.error = 0;
      const url: string = `${process.env.VUE_APP_API_LOCATION}/landing-job`;
      const formData  = new FormData();

      formData.append('fullName', this.careerForm.fullName);
      formData.append('email', this.careerForm.email);
      formData.append('position', this.getCareerName());
      formData.append('numberPhone', this.careerForm.numberPhone);
      formData.append('website', this.careerForm.website);
      formData.append('notes', this.careerForm.notes);
      if (this.careerForm.resume) {
        formData.append('resume', this.careerForm.resume);
      }
      if (this.careerForm.otherUpload) {
        formData.append('otherUpload', this.careerForm.otherUpload);
      }

      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      axios.post(url, formData, config)
        .then((response: any) => {
          this.submitting = false;
          this.careerForm = new CareerForm();
          this.submitSuccess = true;
        }).catch((error: any) => {
          this.submitting = false;
          this.error = error.response.data.status_code;
      });
    },
    getCareerName(): any {
      if (this.position) {
        // return this.$t(`career.opening.career.${this.position}.name`);
        return careerAvailable[`${this.position}`].name;

      } else { return 'Other'; }
    },
    getError(): any {
      if (this.error === 422 ) {
        return this.$t(`joinCareer.error.${this.error}`);
      } else {
        return 'Something went wrong.';
      }
    },
  },
  created() {
    this.position =  this.$route.params.position;
  },
  components: {
    NavBar,
    Footer,
    FileUploader,
  },
});
