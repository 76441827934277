






























import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default Vue.extend({
  data(): {
  } {
    return {

    };
  },
  components: {
    NavBar,
    Footer,
  },
});
