import store from '@/store';

export default {
    computed: {
        currentLang() {
            return (store.state as any).lang.language;
        },
        consultWithPsychologist() {
            if (this.currentLang) {
                return require(`../assets/chevron/consult-with-the-doctor-${this.currentLang}.png`);
            }
        },
    },
};
