













































































































































































































































































// import vueSlider from 'vue-slider-component';
import store from '@/store';
import { Component, Prop , Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import 'aos/dist/aos.css'

export default Vue.extend({
  data() {
    return {
      sliderValue: 1,
      sliderOptions: {
        eventType: 'auto',
        width: 'auto',
        height: 6,
        dotSize: 30,
        min: 1,
        max: 7,
        interval: 1,
        show: true,
        speed: 0.5,
        disabled: false,
        piecewise: false,
        piecewiseLabel: false,
        tooltip: false,
        tooltipDir: 'top',
        reverse: false,
        data: null,
        clickable: true,
        realTime: false,
        lazy: false,
        formatter: null,
        bgStyle: null,
        sliderStyle: null,
        processStyle: null,
        piecewiseActiveStyle: null,
        piecewiseStyle: null,
        tooltipStyle: null,
        labelStyle: null,
        labelActiveStyle: null,
      },
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
    };
  },
  computed: {
    slider(): any {
      return this.sliderValue;
    },
    currentLang() {
      return (store.state as any).lang.language;
    },
    customizationImg() {
      if ( this.currentLang === 'th' ) {
        return require('../assets/corp-customization-side-th.svg');
      }
      return require('../assets/corp-customization-side.svg');
    },
  },
  methods: {
  },
  components: {
    NavBar,
    Footer,
    // vueSlider,
  },
  mounted() {
    import('aos').then(AOS => AOS.init())
  }
});
