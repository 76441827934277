import extend from 'extend';

import corporate from './components/corporate';
import counseling from './components/counseling';
import contactSales from './components/contactSales';
import navBar from './components/navBar';
import home from './components/home';
import joinProvider from './components/joinProvider';
import provider from './components/provider';
import career from './components/career';
import joinCareer from './components/joinCareer';
import pricing from './components/pricing';
import voucher from './components/voucher';
import voucherCompleted from './components/voucherCompleted';
import privacyPolicy from './components/privacyPolicy';
import footer from './components/footer';
import notFound from './components/notFound';
import chevron from './components/corporate/chevron';
import validation from './components/validation';

const components = [];
const target = {};

components.push(corporate);
components.push(counseling);
components.push(contactSales);
components.push(navBar);
components.push(home);
components.push(joinProvider);
components.push(provider);
components.push(career);
components.push(joinCareer);
components.push(pricing);
components.push(voucher);
components.push(voucherCompleted);
components.push(privacyPolicy);
components.push(footer);
components.push(notFound);
components.push(chevron);
components.push(validation);

extend(true, target, ...components);

export default target;
