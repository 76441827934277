export default {
  en: {
    voucherCompleted: {
      title: 'Your voucher is ready!',
      total: 'Total amount',
      baht: 'THB',
      orderId: 'Order ref.',
      text1: 'Your gift voucher has already been purchased',
      text2: 'and send to the receiver\'s email.',
      text31: 'please contact',
      text32: 'if you need help.',
    },
  },
  th: {
    voucherCompleted: {
      title: 'การสั่งซื้อเสร็จสมบูรณ์!',
      total: 'จำนวนทั้งหมด',
      baht: 'บาท',
      orderId: 'เลขอ้างอิงคำสั่งซื้อ',
      text1: 'การสั่งซื้อบัตรของขวัญของคุณเสร็จสมบูรณ์แล้ว',
      text2: 'ระบบได้ส่งบัตรของขวัญไปยังอีเมลผู้รับเรียบร้อยแล้ว',
      text31: 'หากพบปัญหาในการใช้งานกรุณาติดต่อ',
      text32: '',
    },
  },
};
