export default {
  en: {
    contactSales: {
      title: 'Contact Ooca sales team',
      subtitle: 'We are happy to help! Feel free to leave your inquiries here'
      + 'and we’ll be in touch as soon as possible',
      form: {
        title: 'Contact person',
        fullName: {
          label: 'Your full name',
          placeholder: 'Jane Carter',
        },
        email: {
          label: 'Your work email',
          placeholder: 'jane@corporate.com',
        },
        website: {
          label: 'Your corporate website',
          placeholder: 'corporate.com',
        },
        size: {
          label: 'Corporate size (approx. employees)',
          choices: {
            small: 'Small: <50 employees',
            medium: 'Medium: <250 employees',
            large: 'Large: >250 employees',
          },
        },
        requirements: {
          label: 'Any special requirements?',
          placeholder: 'Your message',
          limit: '250 characters max',
        },
        btn: 'Contact sales',
      },
    },
  },
  th: {
    contactSales: {
      title: 'ติดต่อฝ่ายขาย',
      subtitle: 'เราพร้อมพูดคุยและให้คำปรึกษา โปรดกรอกข้อมูลเบื้องต้นและความต้องการขององค์กรของคุณ'
      + 'ทีมงานของเราจะติดต่อกลับไปให้เร็วที่สุด',
      form: {
        title: 'ผู้ประสานงาน',
        fullName: {
          label: 'ชื่อของคุณ',
          placeholder: 'เจน คาร์เตอร์',
        },
        email: {
          label: 'อีเมลองค์กร',
          placeholder: 'jane@corporate.com',
        },
        website: {
          label: 'เว็บไซต์องค์กร',
          placeholder: 'corporate.com',
        },
        size: {
          label: 'ขนาดองค์กร (จำนวนพนักงานโดยประมาณ)',
          choices: {
            small: 'ขนาดเล็ก: <50 คน',
            medium: 'ขนาดกลาง: <250 คน',
            large: 'ขนาดใหญ่: >250 คน',
          },
        },
        requirements: {
          label: 'กรอกความต้องการพิเศษ',
          placeholder: 'พิมพ์ข้อความที่นี่',
          limit: 'ไม่เกิน 250 ตัวอักษร',
        },
        btn: 'ติดต่อฝ่ายขาย',
      },
    },
  },
};
