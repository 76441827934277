export default {
  en: {
    footer: {
      terms: 'Terms and conditions',
      privacy: 'Privacy policy',
      refund: 'Refund policy',
      contactUs: 'Contact Us',
      followUs: 'Follow Us',
    },
  },
  th: {
    footer: {
      terms: 'เงื่อนไขการใช้บริการ',
      privacy: 'นโยบายความเป็นส่วนตัว',
      refund: 'นโยบายการคืนเงิน',
      contactUs: 'ติดต่อเรา',
      followUs: 'ติดตามเรา',
    },
  },
};
