export default {
  en: {
    voucher: {
      hero: {
        title: 'Let us take care of your loved one',
        subtitle: 'Let them know your love and caring by giving away ' +
        'Ooca gift voucher to the special one in special occasion.',
        callToAction: 'Send a gift',
      },
      video: {
        caption: 'How to use Ooca voucher video tutorial',
      },
      progress: {
        step1: 'Select Gift',
        step2: 'Receiver',
        step3: 'Checkout',
      },
      common: {
        start_at: 'Start at',
        per_session: 'per session',
        thirty_minutes: '(30 minutes)',
        send_gift: 'Send Gift Voucher',
        continue: 'continue',
        time: '30 mins x 1',
        vat: 'vat +7%',
        gatewayFee: 'payment gateway fee',
        total: 'total',
        thb: 'thb',
      },
      psychologist: {
        title: 'Mind therapy',
        subtitle: 'Session with Psychologist',
        session: 'Psychologist session',
      },
      psychiatrist: {
        title: 'Figure out life',
        subtitle: 'Consult with Psychiatrist',
        session: 'Psychiatrist session',
      },
      formDetail: {
        title: 'Enter your gift voucher detail',
        from: {
          title: 'From',
          name: 'Name',
          placeholderFirstname: 'Firstname',
          placeholderLastname: 'Lastname',
          tel: 'Tel.',
          placeholderTel: 'Your Tel',
          email: 'Email',
          placeholderEmail: 'Your email',
        },
        to: {
          title: 'To',
          name: 'Name',
          placeholderName: 'Recipient\'s name',
          tel: 'Tel.',
          placeholderTel: 'Tel.',
          email: 'Email',
          placeholderEmail: 'Recipient\'s email',
          message: 'Add a personalized message',
          placeholderMessage: 'Your message',
          messageLimit: '250 characters max',
        },
      },
      formPayment: {
        title: 'Enter your payment details',
        cardHolderName: 'Card Holder Name',
        placeholderName : 'Firstname Lastname',
        card: 'Credit card number',
        placeholderCard: '**** **** **** ****',
        expiration: 'Expiration',
        placeholderExpiration: 'MM/YY',
        placeholderCvv: 'CVV',
        send: 'Send gift voucher',
      },
    },
  },
  th: {
    voucher: {
      hero: {
        title: 'ให้อูก้าได้ดูแลคนที่คุณรัก',
        subtitle: 'มอบความรักและความห่วงใยผ่านบัตรของขวัญอูก้า ให้คนพิเศษของคุณได้ในทุกโอกาส',
        callToAction: 'ส่งของขวัญ',
      },
      video: {
        caption: 'ขั้นตอนการใช้งานบัตรของขวัญอูก้า',
      },
      progress: {
        step1: 'เลือกของขวัญ',
        step2: 'ข้อมูลผู้รับ',
        step3: 'ชำระเงิน',
      },
      common: {
        start_at: 'เริ่มต้นที่',
        per_session: 'ต่อครั้ง',
        thirty_minutes: '(30 นาที)',
        send_gift: 'ส่งบัตรของขวัญ',
        continue: 'ดำเนินการต่อ',
        time: '30 นาที x 1',
        vat: 'vat +7%',
        gatewayFee: 'ค่าบริการบัตรเครดิต',
        total: 'รวม',
        thb: 'บาท',
      },
      psychologist: {
        title: 'Mind therapy',
        subtitle: 'คุยกับนักจิตวิทยา',
        session: 'ปรึกษานักจิตวิทยา',
      },
      psychiatrist: {
        title: 'Figure out life',
        subtitle: 'ปรึกษาจิตแพทย์',
        session: 'ปรึกษาจิตแพทย์',
      },
      formDetail: {
        title: 'กรอกรายละเอียดบัตรของขวัญ',
        from: {
          title: 'จาก',
          name: 'ชื่อผู้ส่ง',
          placeholderFirstname: 'ชื่อ',
          placeholderLastname: 'สกุล',
          tel: 'เบอร์โทรศัพท์',
          placeholderTel: 'เบอร์โทรศัพท์ผู้ส่ง',
          email: 'Email',
          placeholderEmail: 'อีเมลผู้ส่ง',
        },
        to: {
          title: 'ถึง',
          name: 'ชื่อผู้รับ',
          placeholderName: 'ชื่อ',
          tel: 'เบอร์โทรศัพท์',
          placeholderTel: 'เบอร์โทรศัพท์ผู้รับ',
          email: 'Email',
          placeholderEmail: 'อีเมลผู้รับ',
          message: 'ส่งข้อความถึงผู้รับบัตรของขวัญ',
          placeholderMessage: 'พิมพ์ข้อความที่นี่',
          messageLimit: 'ไม่เกิน 250 ตัวอักษร',
        },
      },
      formPayment: {
        title: 'กรอกรายละเอียดการชำระเงิน',
        card: 'หมายเลขบัตรเครดิต',
        cardHolderName: 'ชื่อผู้ถือบัตร',
        placeholderName : 'ชื่อ นามสกุล',
        placeholderCard: '**** **** **** ****',
        expiration: 'วันหมดอายุ',
        placeholderExpiration: 'ดด/ปป',
        placeholderCvv: 'CVV',
        send: 'ส่งบัตรของขวัญ',
      },
    },
  },
};
