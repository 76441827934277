






















import store from '@/store';
import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  props: {
    label: {
      type: String,
    },
    deleteLabel: {
      type: String,
    },
    value: {
      type: File,
    },
    identifier: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onChange(event: any) {
      const input = event.target;
      if (input.files && input.files[0]) {
        this.$emit('input', input.files[0]);
      } else {
        this.$emit('input', null);
      }
    },
    clearFile() {
      (this.$refs.fileInput as any).value = '';
      this.$emit('input', null);
    },
  },
  computed: {
  },
});
