import Vue from 'vue';
import Router from 'vue-router';
// import _ from 'lodash';
const _ = require('lodash');

import Home from './views/Home.vue';
import Pricing from './views/Pricing.vue';
import Voucher from './views/Voucher.vue';
import VoucherCompleted from './views/VoucherCompleted.vue';
import Providers from './views/Providers.vue';
import JoinProvider from './views/JoinProvider.vue';
import About from './views/About.vue';
import Support from './views/Support.vue';
import Career from './views/Career.vue';
import JoinCareer from './views/JoinCareer.vue';
import Corporate from './views/Corporate.vue';
import Counseling from './views/Counseling.vue';
import ContactSales from './views/ContactSales.vue';
import Faq from './views/Faq.vue';
import Terms from './views/Terms.vue';
import Privacy from './views/PrivacyPolicy.vue';
import Refund from './views/RefundPolicy.vue';
import Norway from './views/Norway.vue';
import PTT from './views/PTT.vue';
import SCG from './views/SCG.vue';
import Chevron from './views/Chevron.vue';
import BOT from './views/BOT.vue';
import MFA from './views/MFA.vue';
import HowToUseCorporate from './views/HowToUseCorporate.vue';
import NotFound from './views/NotFound.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (from && to.name === from.name) {
      return;
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    { path: '/th', redirect: '/' },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: Pricing,
    },
    {
      path: '/voucher',
      name: 'voucher',
      component: Voucher,
    },
    {
      path: '/voucher/:voucherid',
      name: 'voucherCompleted',
      component: VoucherCompleted,
    },
    {
      path: '/providers',
      name: 'providers',
      component: Providers,
    },
    {
      path: '/join-provider',
      name: 'joinProvider',
      component: JoinProvider,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
    },
    {
      path: '/norway',
      name: 'norway',
      component: Norway,
    },
    {
      path: '/mfa',
      name: 'mfa',
      component: MFA,
    },
    {
      path: '/ptt',
      name: 'ptt',
      component: PTT,
    },
    {
      path: '/scgchem',
      name: 'scg',
      component: SCG,
    },
    {
      path: '/chevron',
      name: 'chevron',
      component: Chevron,
    },
    {
      path: '/bot',
      name: 'bot',
      component: BOT,
    },
    {
      path: '/how-to-use-corporate',
      name: 'howToUseCorporate',
      component: HowToUseCorporate,
    },
    {
      path: '/career',
      name: 'career',
      component: Career,
      redirect: { name: 'career2' ,params: { position : 'accountExecutive'} }
    },
    {
      path: '/career/:position',
      name: 'career2',
      component: Career,
      beforeEnter: (to, from, next) => {
        if (
          [
            'reactNativeDeveloper',
            'seniorFullStackDeveloper', 
            'accountExecutive',
            'teleSalesRepresentative',
            'businessDevelopment',
            'socialImpactManager',
            'marketingCommunication',
            'digitalMediaBuyer',
            'teamAssist',
            'hrAdministrator',
            'operationsManager',
            'customerService',
            'providerRelationsManager',
            'psychologist',
          ].includes(to.params.position)
        ) {
          next();
        } else {
          next({
            name: '404',
            replace: true,
          });
        }
      },
    },
    {
      path: '/career/:position/apply',
      name: 'joinCareer',
      component: JoinCareer,
      beforeEnter: (to, from, next) => {
        if (
          [
            'reactNativeDeveloper',
            'seniorFullStackDeveloper', 
            'accountExecutive',
            'teleSalesRepresentative',
            'businessDevelopment',
            'socialImpactManager',
            'marketingCommunication',
            'digitalMediaBuyer',
            'teamAssist',
            'hrAdministrator',
            'operationsManager',
            'customerService',
            'providerRelationsManager',
            'psychologist',
          ].includes(to.params.position)
        ) {
          next();
        } else {
          next({
            name: '404',
            replace: true,
          });
        }
      },
    },
    {
      path: '/corporate',
      name: 'corporate',
      component: Corporate,
    },
    {
      path: '/counseling',
      name: 'counseling',
      component: Counseling,
    },
    {
      path: '/contact-sales',
      name: 'contactSales',
      component: ContactSales,
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq,
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
    },
    {
      path: '/privacy-policy',
      alias: '/privacy',
      name: 'privacyPolicy',
      component: Privacy,
    },
    {
      path: '/refund-policy',
      name: 'refundPolicy',
      component: Refund,
    },
    {
      path: '/ios',
      name: 'ios',
      beforeEnter() {
        location.href =
          'https://itunes.apple.com/us/app/ooca/id1260476046?ls=1&mt=8';
      },
    },
    {
      path: '/android',
      name: 'android',
      beforeEnter() {
        location.href =
          'https://play.google.com/store/apps/details?id=co.ooca.user&pcampaignid' +
          '=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
      },
    },
    {
      path: '*',
      name: 'notFound',
      component: NotFound,
    },
    {
      path: '/404',
      name: '404',
      component: NotFound,
    },
  ],
});
