

























































































































































































































































































































































































































import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import 'aos/dist/aos.css'

class Provider {
  public logo: string;
  public quote: string;
  public logoType: string;
  constructor(logo: string, quote: string , type: string) {
    this.logo = logo;
    this.logoType = type;
    this.quote = quote;
  }
}


export default Vue.extend({
  data(): {
    providers: Provider[],
    providerIndex: number,
    ProvidersIterationInterval: number,
    webAppUrl: string,
  } {
    return {
      providers: [
        new Provider('sukamon', 'sukamon' , 'svg'),
        new Provider('pimploy', 'pimploy' , 'svg'),
        new Provider('korboon', 'korboon' , 'svg'),
        new Provider('methinee', 'methinee' , 'jpg'),
        new Provider('kittisak', 'kittisak' , 'jpg'),
        new Provider('varangkana', 'varangkana' , 'jpg'),
        new Provider('suleeporn', 'suleeporn' , 'jpg'),
        // new Provider('nopphasit', 'nopphasit' , 'jpg'),
        new Provider('yuratchar', 'yuratchar' , 'jpg'),
      ],
      providerIndex: 0,
      ProvidersIterationInterval: 0,
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
    };
  },
  computed: {
    currentProvider(): Provider {
      return this.providers[this.providerIndex];
    },
    ProviderSwiper(): any {
      return (this.$refs.ProviderSwiper as any).swiper;
    },
  },
  methods: {
    getProviderLogoUrl(provider: any) {
      return require(`../assets/providers/${provider.logo}.${provider.logoType}`);
    },
    shiftProviders() {
      this.providerIndex = (this.providerIndex + 1) % this.providers.length;
      this.ProviderSwiper.slideNext();
    },
    slideProviderTo(index: number) {
      this.ProviderSwiper.slideToLoop(index);
    },
    getCurrentProviderAvatar() {
      // return require(`../assets/providers/${this.currentProvider.quote}.svg`);
      // return require(`../assets/testimonials/avatar-ptt.png`);
    },
    getcurrentProviderQuote() {
      return this.$t(`provider.doing.quotes.${this.currentProvider.quote}`);
    },
    getcurrentProviderName() {
      return this.$t(`provider.doing.name.${this.currentProvider.quote}`);
    },
  },
  mounted() {
    import('aos').then(AOS => AOS.init())
    this.ProvidersIterationInterval = window.setInterval(this.shiftProviders, 10000);
    this.ProviderSwiper.on('slideChangeTransitionEnd', () => {
      this.providerIndex = this.ProviderSwiper.realIndex;
    });
  },
  beforeDestroy() {
    window.clearInterval(this.ProvidersIterationInterval);
  },
  components: {
    NavBar,
    Footer,
    swiper,
    swiperSlide,
  },
});
