<template>
    <div id="app" class="dont-allow-select">
        <Modal v-if="showAlert && announcement" @close="showAlert = false" class="z-9999">
            <header slot="header" v-if="!landing_full_srceen">
                <div class="flex justify-end">
                    <i class="fas fa-times fa-lg pt-4 pr-4 cursor-pointer" @click="showAlert = false"></i>
                </div>
            </header>
            <header slot="header" v-else>
            </header>
            <body slot="body" v-if="!landing_full_srceen">
                <div class="pt-0 md:p-20 p-4" v-html="announcement">
                </div>
            </body>
            <body slot="body" class="relative" v-else>
                <i class="fas fa-times fa-lg pt-4 pr-4 cursor-pointer absolute pin-r" @click="showAlert = false"></i>
            <div class="" v-html="announcement">
            </div>
            </body>
            <footer slot="footer"></footer>
        </Modal>
        <router-view/>
    </div>
</template>

<script type="ts">
    import '@/assets/styles/main.css';
    import Modal from './components/Modal.vue';
    const moment = require('moment-timezone');
    import announcementService from './services/announcement'

    export default {
        name: 'app',
        data() {
            return {
                showAlert: true,
                announcement: null,
                landing_full_srceen: false,
            };
        },
        components: {
            Modal,
        },
        mounted() {
            announcementService.all().then(data => {
                this.announcement = data.user;
                this.landing_full_srceen = data.landing_full_srceen;
                this.showAlert = false;
                if (data.user) {
                    if(this.announcement && moment.utc().isBetween(moment.utc(data.start_at), moment.utc(data.expired_at))) {
                        this.showAlert = true;
                    }
                }
            })
        }
    };

</script>

<style lang="stylus">
    #app
        /*font-family 'Avenir', Helvetica, Arial, sans-serif*/
        -webkit-font-smoothing antialiased
        -moz-osx-font-smoothing grayscale
        overflow-x: hidden;

    .z-9999 {
        z-index 9999;
    }

    .dont-allow-select {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
    /*text-align center*/
    /*color #2c3e50*/

    /*#nav*/
    /*padding 30px*/
    /*a*/
    /*font-weight bold*/
    /*color #2c3e50*/
    /*&.router-link-exact-active*/
    /*color #42b983*/
</style>
