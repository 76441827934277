import store from '@/store';

export default {
    computed: {
        currentLang() {
            return (store.state as any).lang.language;
        },
        moreDetail() {
            if (this.currentLang) {
                return require(`../assets/see-more-detail-${this.currentLang}.png`);
            }
        },
    },
};
