export default {
  en: {
    pricing: {
      howOocaCost: {
        title: 'how ooca cost',
        subTitle1: 'Ooca service fee starting at 1,000 THB.',
        subTitle2: 'Pricing and fee charged depend on selected provider.',
        package30: '30-minute Package',
        package60: '60-minute Package',
        prepaid60: '60 mins.<br> Prepaid',
        prepaid30: '30 mins.<br> Prepaid',
        noAddition: 'No additional<br>charges',
        sessionAfter41: 'Session after 41 mins will be charge as 30 mins',
      },
      timingIsCrucial: {
        title: 'timing is crucial',
        subTitle:
          'We do care about your counselling experiences.<br>' +
          'So we provided packages as 30 minutes and 60 minutes sessions',
        icons: {
          controllableSchedule: 'controllable schedule',
          stayFocus: 'stay focus',
          efficacyTherapy: 'efficacy therapy',
        },
      },
      oocaProvider: {
        title: 'ooca provider',
        subTitle:
          'At Ooca we have a team of mental health providers that you ' +
          'can choose from based on your preferences and clinical needs.',
        startAt: 'Start at',
        perSession: 'per session (30 minutes)',
        makeAppointmentNow: 'make appointment now',
        baht: 'baht',
        card1: {
          psychologist: 'psychologist',
          text:
            'Psychologists are counselors who use therapy to learn about what you’re' +
            ' going through. They listen first and then offer coping strategies to help' +
            ' you get back to feeling like yourself.',
          lists: {
            1: 'Postgraduate Degree in Counseling',
            2: 'Or Board-licensed in Clinical Psychology',
            3: 'Prevention and Provide Counseling Session',
            4: 'Monitor Physical Health',
          },
        },
        card2: {
          psychiatrist: 'psychiatrist',
          text:
            'Psychiatrists are physicians who specialize in medication management.' +
            ' Psychiatrist will conduct an assessment and recommend a treatment plan ' +
            ' that may include a combination of therapy and medication.',
          lists: {
            1: 'Medical Doctor',
            2: 'Board-licensed in Psychiatry',
            3: 'Prevention, Treatment, and Diagnosis',
            4: 'Monitor Physical Health',
          },
        },
      },
      paymentMedthods: {
        title: 'payment methods',
        subTitle: 'We provided the easiest way to pay for your wellness.',
        lists: {
          creditCard: 'Credit/Debit Card accepted',
          purchase: 'Purchase Gift voucher',
          noCredit: 'No credit card? Don’t worry.',
          buyVoucher: 'buy gift voucher >',
        },
      },
      payAsYouGo: {
        title: 'pay as you go',
        subTitle:
          'Easily lengthen your session with ' +
          'seamlessly counselling experience',
        warning: '*Only available for payment via credit or debit Card',
      },
    },
  },
  th: {
    pricing: {
      howOocaCost: {
        title: 'อูก้าคิดเงินอย่างไร',
        subTitle1: 'ค่าบริการการใช้งานระบบอูก้าเริ่มต้นเพียง 1,000 บาท',
        subTitle2:
          'การคิดค่าบริการจะขึ้นอยู่กับประเภทผู้ให้คำปรึกษาและระยะเวลาในการปรึกษา',
        package30: '30-minute Package',
        package60: '60-minute Package',
        prepaid60: 'เติมเงิน สำหรับใช้<br>บริการ 60 นาที',
        prepaid30: 'เติมเงิน สำหรับใช้<br>บริการ 30 นาที',
        noAddition: 'ไม่คิดค่าบริการเพิ่ม',
        sessionAfter41:
          'การใช้บริการหลังจากนาทีที่ 41 จะคิดค่าบริการเพิ่มอีก 30 นาที',
      },
      timingIsCrucial: {
        title: 'เวลาที่เหมาะสม',
        subTitle:
          'เพราะเราใส่ใจคุณและอยากคงคุณภาพการให้คำปรึกษาที่ดีที่สุด<br>' +
          'ระยะเวลาการปรึกษาที่เราแนะนำคือ 30 นาที หรือ 60 นาที',
        icons: {
          controllableSchedule: 'กำหนดเวลาเองได้',
          stayFocus: 'มีสมาธิเต็มที่',
          efficacyTherapy: 'ผลลัพธ์เป็นที่พอใจ',
        },
      },
      oocaProvider: {
        title: 'ผู้ให้คำปรึกษาของอูก้า',
        subTitle:
          'เรามีทีมผู้ให้คำปรึกษาที่เชี่ยวชาญด้านจิตใจเฉพาะทาง ' +
          'คุณสามารถเลือกได้ตามความต้องการ',
        startAt: 'เริ่มต้นที่',
        perSession: 'ต่อครั้ง (30 นาที)',
        makeAppointmentNow: 'สร้างการนัดหมาย',
        baht: 'บาท',
        card1: {
          psychologist: 'นักจิตวิทยา',
          text:
            'นักจิตวิทยาคือผู้ให้คำปรึกษาที่ใช้การพูดคุย' +
            ' ให้คำปรึกษาในการบำบัด บรรเทาความทุกข์ใจ' +
            ' ด้วยการรับฟังและให้แนวทางในการรับมือ' +
            ' ความเครียดที่เหมาะสมกับผู้รับบริการแต่ละคน',
          lists: {
            1: 'ปริญญาโท หรือปริญญาเอกจิตวิทยาการปรึกษา',
            2: 'หรือมีใบประกอบโรคศิลป์จิตวิทยาคลินิก',
            3: 'ป้องกัน และบรรเทา',
            4: 'ประเมินร่วมกับสุขภาพกาย',
          },
        },
        card2: {
          psychiatrist: 'จิตแพทย์',
          text:
            'จิตแพทย์คือหมอที่จะประเมินอาการของคุณ ' +
            'ทั้งทางร่างกายและจิตใจ จากนั้นจึงวินิจฉัย ' +
            'และแนะนำวิธีการบำบัดรักษาตามความเหมาะสมของอาการ ' +
            'ซึ่งอาจประกอบด้วยการบำบัดและรักษาทางกายภาพ ',
          lists: {
            1: 'แพทยศาสตรบัณฑิต',
            2: 'มีวุฒิบัตร สาขาจิตเวชศาสตร์',
            3: 'ป้องกัน บรรเทาและวินิจฉัย',
            4: 'ประเมินร่วมกับสุขภาพกาย',
          },
        },
      },
      paymentMedthods: {
        title: 'วิธีการชำระเงิน',
        subTitle:
          'เรามีช่องทางการชำระเงินที่ช่วยให้การชำระค่าปรึกษาสะดวกกว่าที่เคย',
        lists: {
          creditCard: 'รองรับบัตรเครดิต/บัตรเดบิต',
          purchase: 'เติมเงินด้วยบัตรของขวัญ',
          noCredit: 'ไม่มีเครดิตการ์ดก็ใช้บริการได้',
          buyVoucher: 'ซื้อบัตรของขวัญได้ที่นี่ >',
        },
      },
      payAsYouGo: {
        title: 'จ่ายเท่าที่คุณใช้',
        subTitle:
          'ลองปรึกษาระยะสั้นก่อนได้ คุยเท่าไร จ่ายตามจริงเท่านั้น ' +
          'หรือจะคุยเพิ่มก็ต่อเวลาการปรึกษาของคุณได้อย่างไม่สะดุด',
        warning: '',
      },
    },
  },
};
