export default {
  en: {
    home: {
      hero: {
        title:
          'It\'s okay. Let\'s talk',
        subtitle:
          'with psychologist and psychiatrist from the comfort of your home.',
        callToAction: 'try it out',
      },
      whatOoca: {
        title: 'What is ooca?',
        text:
          'You can get connected with our psychologist and psychiatrist through online video call. ' +
          'Using Ooca on your phone iOS , android or your computer anywhere that you want with ' +
          'privacy and highest security anonymously.',
      },
      press: 'press',
      ourValue: {
        title: 'Our Value',
        subtitle: 'We improve your satisfaction with telemedicine',
        flexibility: {
          title: 'More Flexibility',
          text: 'See the doctor from the comfort of home or anywhere.',
        },
        confidentiality: {
          title: 'Confidentiality',
          text:
            'You will feel comfortable to share anything to the doctor without any exposure.',
        },
        time: {
          title: 'No time wasting',
          text:
            'Avoid wasting your time in crowded, uncomfortable waitingrooms.',
        },
      },
      services: {
        title: 'Our services',
        subtitle: 'We improve your satisfaction with telemedicine',
        counseling: {
          title: 'Counseling',
          text:
            'A robust evidence base that shows telepsychiatry leading ' +
            'to improved outcomes and higher patient satisfaction.',
          providers: 'Are you a psychiatist or psychologist?',
          join: 'Join as a provider >',
        },
        provider: {
          title: 'Your Professional Counselor',
          text:
            'Choose one from our team of psychiatrists and Psychologist.' +
            ' We are always willing to comfort and go through the journey with you.',
          providers: 'Are you a psychiatist or psychologist?',
          join: 'Join as a provider >',
        },
        stressTest: {
          title: 'Stress Test',
          text:
            'Not sure if you need help? ' + 'Why not try out our stress test',
          info1:
            'OOCA designed this test to help you understand and handle your stress.',
          info2:
            'Understand your situation and your level of stress and can take care of it.',
          btn: 'Test now',
        },
        gift: {
          title: 'Gift for your loved one',
          text: 'Let us express your care for your significant one.',
          btn: 'Get the code',
        },
      },
      howItWork: {
        title: 'How it works',
        subtitle:
          'Using Ooca is simple and convenient with just a few steps to go',
        faq: 'FREQUENTLY ASKED QUESTIONS >',
        signUp: {
          title: 'Get Ooca and Sign up',
        },
        appoint: {
          title: 'Choose time & doctor',
        },
        call: {
          title: 'Wait for doctor to Video Call',
        },
      },
      pricing: {
        title: 'Pricing & Fees',
        subtitle:
          'Save your time and spend it wisely, Live the life that you own',
        linkFullPricing: 'SEE FULL PRICING >',
        personalPlan: {
          title: 'Personal plan',
          text: 'for your own wellness',
          btn: 'Get started',
        },
        corporatePlan: {
          title: 'Corporate plan',
          text: 'for your team wellness',
          btn: 'See pricing',
        },
      },
      getStarted: {
        title: 'Ready to get started?',
        subtitle: 'Let ooca take care of you or the one you loved.',
        btnGetStarted: 'Get started',
        btnSendGift: 'Send a gift',
      },
      blog: {
        title: 'Lifestyle articles from ooca',
        text:
            'Insightful stories that will help you comprehend and understand yourself better,' +
            'which are portrayed by our fine writers, first-hand experienced writers, and of course, our providers.',
        btn: 'Let\'s read!!'
      },
      testimonials: {
        title: 'Our partners and clients',
        quotes: {
          rabbit:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Nulla blandit dui enim, sit amet dapibus lorem fermentum a. Nunc ullamcorper.',
          scg:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Nulla blandit dui enim, sit amet dapibus lorem fermentum a. Nunc ullamcorper.',
          ega:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Nulla blandit dui enim, sit amet dapibus lorem fermentum a. Nunc ullamcorper.',
        },
        credits: {
          rabbit: 'John Smith, CEO of PTT Group',
          scg: 'John Smith, CEO of PTT Group',
          ega: 'John Smith, CEO of PTT Group',
        },
      },
    },
  },
  th: {
    home: {
      hero: {
        title:
          'ปรึกษาปัญหาคาใจกับจิตแพทย์และนักจิตวิทยา',
        subtitle: 'จากที่บ้านได้อย่างสะดวกสบาย',
        callToAction: 'เริ่มเลย',
      },
      whatOoca: {
        title: 'อูก้าคืออะไร?',
        text:
          'เราคือ platform ปรึกษาจิตแพทย์ออนไลน์ ' +
          'ที่ช่วยให้คุณสามารถพูดคุยปัญหาคาใจกับจิตแพทย์และนักจิตวิทยาได้ผ่าน ' +
          'video call โดยเข้าใช้งานได้อย่างเป็นส่วนตัวและปลอดภัย ทุกที่ ทุกเวลา' +
          ' ผ่านคอมพิวเตอร์หรือโทรศัพท์มือถือของคุณ ',
      },
      press: 'รวมสื่อที่พูดถึงเรา',
      ourValue: {
        title: 'ทำไมต้องอูก้า',
        subtitle: 'เรายกระดับการปรึกษาปัญหาใจทางไกลเพื่อความพึงพอใจของคุณ',
        flexibility: {
          title: 'ไม่จำกัดสถานที่',
          text:
            'คุณสามารถพูดคุยกับจิตแพทย์ ได้จากที่บ้านหรือที่ไหนก็ได้ อย่างสะดวกสบาย',
        },
        confidentiality: {
          title: 'มั่นใจได้ว่าเป็นความลับ',
          text:
            'ไม่ต้องรู้สึกกลัวคนอื่นมองไม่ดี เพราะด้วยระบบการปรึกษาผ่าน video call จะทำให้คุณสบายใจ',
        },
        time: {
          title: 'ไม่เสียเวลารอ',
          text:
            'แทนที่จะต้องนั่งรอพบแพทย์ที่โรงพยาบาล คุณสามารถเลือกพบแพทย์ในเวลาไหนก็ได้ที่คุณสะดวก',
        },
      },
      services: {
        title: 'บริการของเรา',
        subtitle:
          'นอกจากอูก้าจะช่วยให้คุณเชื่อมต่อกับผู้ให้คำปรึกษาแล้ว ' +
          'คุณยังสามารถลองทำแบบทดสอบ หรือมอบอูก้าเป็นของขวัญให้คนที่คุณรักได้',
        counseling: {
          title: 'บริการให้คำปรึกษา',
          text:
            'เรามุ่งมั่นให้บริการอย่างมีคุณภาพและรับประกันความพึงพอใจ ' +
            'เพื่อให้การปรึกษาทางไกลทำให้คุณภาพชีวิตของคุณดีขึ้น',
          providers: 'คุณเป็นจิตแพทย์หรือนักจิตวิทยาหรือไม่?',
          join: 'ร่วมงานกับเรา >',
        },
        provider: {
          title: 'ที่ปรึกษามืออาชีพ',
          text:
            'เลือกทีมจิตแพทย์ และนักจิตวิทยามากประสบการณ์ พร้อมให้คำปรึกษา ร่วมทางไปกับคุณเสมอ',
          providers: 'คุณเป็นจิตแพทย์หรือนักจิตวิทยาหรือไม่?',
          join: 'ร่วมงานกับเรา >',
        },
        stressTest: {
          title: 'แบบทดสอบความเครียด',
          text:
            'หากไม่แน่ใจว่าคุณจำเป็นต้องปรึกษาจิตแพทย์หรือไม่ ' +
            'ลองทำแบบประเมินด้วยตนเองก่อนเพื่อช่วยตัดสินใจ',
          info1:
            'อูก้าได้ออกแบบแบบทดสอบนี้เพื่อช่วยให้คุณได้เข้าใจตัวเองและรับมือกับความเครียดได้ดีขึ้น',
          info2:
            'รู้ระดับความเครียดของคุณเพื่อหาทางรับมือด้วยตนเอง หรือขอรับบริการให้คำปรึกษา',
          btn: 'ลองทำแบบทดสอบ',
        },
        gift: {
          title: 'ให้อูก้าเป็นของขวัญแก่คนที่คุณรัก',
          text:
            'ให้เราช่วยส่งต่อความหวังดีแด่คนสำคัญของคุณ ด้วยบัตรของขวัญอูก้า',
          btn: 'เลือกบัตรของขวัญ',
        },
      },
      howItWork: {
        title: 'ใช้งานอย่างไร',
        subtitle: 'คุณสามารถเข้าใช้งานอูก้าได้สะดวกรวดเร็วภายในไม่กี่ขั้นตอน',
        faq: 'ลองดูคำถามที่พบบ่อย >',
        signUp: {
          title: 'ดาวน์โหลดอูก้าและลงทะเบียน',
        },
        appoint: {
          title: 'เลือกวันเวลาและผู้ให้บริการ',
        },
        call: {
          title: 'ผู้ให้บริการจะวิดีโอคอลหาคุณ',
        },
      },
      pricing: {
        title: 'ค่าบริการ',
        subtitle:
          'ประหยัดเวลา ใช้เงินอย่างมีคุณค่า ใช้ชีวิตอย่างที่คุณอยากเป็น',
        linkFullPricing: 'อ่านต่อเกี่ยวกับค่าบริการ >',
        personalPlan: {
          title: 'สำหรับบุคคลทั่วไป',
          text: 'เพื่อสุขภาวะที่ดีของคุณ',
          btn: 'เริ่มใช้งาน',
        },
        corporatePlan: {
          title: 'สำหรับองค์กรและหน่วยงาน',
          text: 'เพื่อความสุขของพนักงาน',
          btn: 'ดูเงื่อนไข',
        },
      },
      getStarted: {
        title: 'พร้อมเริ่มใช้งานอูก้าหรือยัง?',
        subtitle: 'ให้อูก้าช่วยดูแลคุณหรือคนที่คุณรัก',
        btnGetStarted: 'เริ่มใช้งานเลย',
        btnSendGift: 'บัตรของขวัญ',
      },
      blog: {
        title: 'บทความ lifestyle จากอูก้า',
        text:
            'เรื่องราวดีๆที่อ่านแล้วจะเข้าใจจิตใจของตัวเองมากขึ้น' +
            'ไม่ว่าจะมาจากนักเขียนของอูก้า' +
            'ผู้มีประสบการณ์ตรงเกี่ยวกับปัญหาสุขภาพจิต' +
            'และผู้ให้คำปรึกษาของอูก้า !',
        btn: 'อ่านเลย!'
      },
      testimonials: {
        title: 'พันธมิตรและลูกค้าของอูก้า',
        quotes: {
          rabbit:
            'ลีนุกซ์กราฟิกส์สเปซเราเตอร์ลูป เทเลคอม คลัสเตอร์แอพพลิเคชันฟอร์เวิร์ดเดเบียน',
          scg:
            'ลีนุกซ์กราฟิกส์สเปซเราเตอร์ลูป เทเลคอม คลัสเตอร์แอพพลิเคชันฟอร์เวิร์ดเดเบียน',
          ega:
            'ลีนุกซ์กราฟิกส์สเปซเราเตอร์ลูป เทเลคอม คลัสเตอร์แอพพลิเคชันฟอร์เวิร์ดเดเบียน',
        },
        credits: {
          rabbit: 'คุณจอน สมิธ, CEO of PTT Group',
          scg: 'คุณจอน สมิธ, CEO of PTT Group',
          ega: 'คุณจอน สมิธ, CEO of PTT Group',
        },
      },
    },
  },
};
