

























































































































































// require styles
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '../components/NavBar.vue';
import Footer from '../components/Footer.vue';
import Modal from '../components/Modal.vue';
import 'aos/dist/aos.css';

export default Vue.extend({
  data(): {
    webAppUrl:string
  } {
    return {
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION
    };
  },
  computed: {
    goToApp() {
      return `${process.env.VUE_APP_WEB_APP_MFA_REGISTER_LOCATION}`;
    }
  },
  methods: {},
  mounted()
  {
    import('aos').then(
      AOS => AOS.init()
      )
  },
  components: {
    NavBar,
    Footer,
    Modal,
  },
});
