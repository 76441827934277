

















































































































































































































































































































































































































































































































import store from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import 'aos/dist/aos.css'

export default Vue.extend({
  data() : {
    webAppUrl: string,
  } {
    return {
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
    }
  },
  components: {
    NavBar,
    Footer,
  },
  computed: {
    currentLang() {
      return (store.state as any).lang.language;
    },
    pathPaymentImg() {
      if ( this.currentLang ) {
        return require(`../assets/payment-${this.currentLang}.svg`) ;
      }
    },
    pathCounsellingImg() {
      if ( this.currentLang ) {
        // return require(`../assets/deviceGif-${this.currentLang}.png`) ;
        return require(`../assets/deviceGif.gif`) ;
      }
    },
  },
  mounted() { import('aos').then(AOS => AOS.init()) }
});
