


















































































































































import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import tel from '@/customValidations/tel';
import email from '@/customValidations/email';
import numberUtils from '../utils/number';




class ContactSalesForm {
  public fullName: string;
  public email: string;
  public website: string;
  public size: string;
  public requirements: string;
  public numberPhone: string;
  constructor() {
    this.fullName = '';
    this.email = '';
    this.website = '';
    this.size = 'small';
    this.requirements = '';
    this.numberPhone = '';
  }
}

export default Vue.extend({
  mixins: [validationMixin],
  data(): {
    contactSalesForm: ContactSalesForm,
    error: number,
    submitting: boolean,
    submitSuccess: boolean,
  } {
    return {
      contactSalesForm: new ContactSalesForm(),
      error : 0,
      submitting: false,
      submitSuccess : false,
    };
  },
  // validations: {
  //   fullName: {
  //     required,
  //     maxLength: maxLength(100),
  //   },
  //   email: {
  //     required,
  //     email,
  //     maxLength: maxLength(100),
  //   },
  //   website: {
  //     required,
  //   },
  //   tel: {
  //     required,
  //     tel,
  //   },
  // },
  methods: {
    submit() {
      this.submitting = true;
      this.error = 0;
      const url: string = `${process.env.VUE_APP_API_LOCATION}/landing-contact-sales`;

      const config = {
        headers: { 'Content-Type': 'application/json'},
      };
      axios.post(url, JSON.stringify(this.contactSalesForm) , config)
        .then((response: any) => {
          this.submitting = false;
          this.contactSalesForm = new ContactSalesForm();
          this.submitSuccess = true;
        }).catch((error: any) => {
        this.submitting = false;
        this.error = error.response.data.status_code;
      });
    },
    getError(): any {
      if (this.error === 422 ) {
        return this.$t(`joinCareer.error.${this.error}`);
      } else {
        return 'Something went wrong.';
      }
    },
  },
  computed: {
    //  isDetailFormInvalid(): boolean {
    //   return this.$v.fullName.$invalid
    //       || this.$v.email.$invalid
    //       || this.$v.tel.$invalid
    //       || this.$v.website.$invalid
    // },
  },
  components: {
    NavBar,
    Footer,
  },
});
