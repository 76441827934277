

































































































































































































































































































































































































































































// require styles
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '../components/NavBar.vue';
import Footer from '../components/Footer.vue';
import Modal from '../components/Modal.vue';
import 'aos/dist/aos.css';

export default Vue.extend({
  data(): {
    showVideoModal: boolean,
    query: object,
  } {
    return {
      showVideoModal: false,
      query : { organization : 'bot' },
    };
  },
  computed: {
    goToApp()  {
      return `${process.env.VUE_APP_WEB_APP_PRIVILEGE_LOCATION}/get-privilege?organizationId=10004`;
    },
  },
  methods: {
    checkBrowser() {
      if (this.detectIE()) {
        alert('ท่านสามารถลงทะเบียนใช้งาน “สุขใจดี” ในช่องทาง www.ooca.co ผ่าน browser Google Chorme หรือ Firefox');
      }
    },
    detectIE() {
      const ua = window.navigator.userAgent;

      const msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      const trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      const edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }

      // other browser
      return false;
    },
  },
  mounted() {
    import('aos').then(
      AOS => AOS.init()
      )
    if (this.$route.query) {
      const query = this.$route.query;
      if (this.$route.query) {
        if (query.email) {
          this.query = Object.assign({}, this.query, { email : query.email});
        }
        if (query.email_verification_token) {
          this.query = Object.assign({}, this.query, { email_verification_token : query.email_verification_token});
        }
      }
    }
  },
  components: {
    NavBar,
    Footer,
    Modal,
  },
});
