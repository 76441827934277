





























































































































































































































































































































































































































































































































































import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import axios from 'axios';
import store from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import Modal from '@/components/Modal.vue';
import 'aos/dist/aos.css'


// class Provider {
//   public logo: string;
//   public name: string;
//   constructor(logo: string, name: string) {
//     this.logo = logo;
//     this.name = name;
//   }
// }

class TypeHelp {
  public name: string;
  public logo: string;
  constructor(name: string, logo: string) {
    this.name = name;
    this.logo = logo;
  }
}

class Topic {
  public name: string;
  public checked: boolean;
  public id: number;
  constructor(name: string, checked: boolean, id: number) {
    this.name = name;
    this.checked = checked;
    this.id = id;
  }
}



export default Vue.extend({
  data(): {
    // providers: Provider[],
    typeHelps: TypeHelp[],
    topics: Topic[],
    providerIndex: number,
    typeHelpIndex: number,
    ProvidersIterationInterval: number,
    TypeHelpIterationInterval: number,
    selectedTopics: any,
    providerList: any,
    showVideoModal: boolean,
    webAppUrl: string,
  } {
    return {
      // providers: [
      //   new Provider('sukamon', '1'),
      //   new Provider('provider5', '2'),
      //   new Provider('provider2', '3'),
      //   new Provider('provider3', '4'),
      //   new Provider('provider4', '5'),
      // ],
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
      typeHelps: [
        new TypeHelp('couple', 'couple'),
        new TypeHelp('child', 'child'),
        new TypeHelp('depression', 'depression'),
        new TypeHelp('family', 'family'),
      ],
      topics: [
        new Topic('stress', false, 19),
        new Topic('relationship', false, 30),
        new Topic('parenting', false, 16),
        new Topic('career', false, 23),
        new Topic('couple', false, 22),
        new Topic('insomnia', false, 28),
        new Topic('learning', false, 17),
        new Topic('depression', false, 20),
        new Topic('school', false, 18),
        new Topic('mood', false, 24),
        new Topic('sex', false, 31),
        new Topic('concentration', false, 33),
      ],
      providerIndex: 0,
      typeHelpIndex: 0,
      ProvidersIterationInterval: 0,
      TypeHelpIterationInterval: 0,
      selectedTopics : [],
      providerList : null,
      showVideoModal: false,
    };
  },
  computed: {
    currentLang() {
      return (store.state as any).lang.language;
    },
    pathIPadImg() {
      if ( this.currentLang ) {
        return require(`../assets/ipad-connected-${this.currentLang}.svg`);
      }
    },
    currentProvider(): any {
      return this.providerList[this.providerIndex];
    },
    ProviderSwiper(): any {
      return (this.$refs.ProviderSwiper as any).swiper;
    },
    TypeHelpSwiper(): any {
      return (this.$refs.TypeHelpSwiper as any).swiper;
    },
    providerTopic(): any {
      if (this.providerList != null) {
        if (this.selectedTopics.length) {
          return this.sortProvider().slice(0, 3);
          // return [];
        } else {
          return this.providerList.slice(0, 3);
        }
      } else { return []; }

    },
  },
  methods: {
    sortProvider() {
      const provider = this.providerList.slice();
      provider.sort((a: any, b: any) => {
        a.point = 0;
        b.point = 0;
        this.selectedTopics.forEach((value: any) => {
          if (a.topics.data.some((o: any) => o.id === value)) { a.point += 1; }
          if (b.topics.data.some((o: any) => o.id === value)) { b.point += 1; }
        });
        return b.point - a.point;
      });
      return provider;
    },
    getProviderLogoUrl(logoName: string) {
      return require(`../assets/providers/${logoName}.svg`);
    },
    getTypeHelpLogoUrl(logoName: string) {
      return require(`../assets/counseling/typeHelp/${logoName}.svg`);
    },
    getTypeHelpName(name: string) {
      return this.$t(`counseling.weHelp.typeHelp.${name}`);
    },
    shiftProviders() {
      this.providerIndex = (this.providerIndex + 1) % this.providerList.length;
      this.ProviderSwiper.slideNext();
    },
    shiftTypeHelps() {
      this.typeHelpIndex = (this.typeHelpIndex + 1) % this.typeHelps.length;
      this.TypeHelpSwiper.slideNext();
    },
    slideProviderTo(index: number) {
      this.ProviderSwiper.slideToLoop(index);
    },
    slideTypeHelpTo(index: number) {
      this.TypeHelpSwiper.slideToLoop(index);
    },
    getCurrentProviderType() {
      return this.$t(`counseling.personalized.provider.type.${this.currentProvider.type}`);

    },
    getCurrentProviderName() {
      return this.currentProvider.prefix[this.currentLang]
        + ' ' + this.currentProvider.firstname[this.currentLang]
        + ' ' + this.currentProvider.lastname[this.currentLang];
    },
    getTopicName(name: string) {
      return this.$t(`counseling.personalized.topics.${name}`);
    },
    getProviderFirstName(provider: any) {
      return provider.firstname[this.currentLang];
    },
    getProviderLastName(provider: any) {
      return provider.lastname[this.currentLang];
    },
    getProviderPrefix(provider: any) {
      return provider.prefix[this.currentLang];
    },
    getProviderTopic(topic: any) {
      return topic[this.currentLang];
    },
    getProviderLink(slug: any) {
      if (slug) {
        return `${this.webAppUrl}/${slug}/`;
      } else { return `${this.webAppUrl}`; }
    },
    getProviderType(provider: any) {
      return this.$t(`counseling.personalized.provider.type.${provider.type}`);
    },
    getProviderCert(provider: any) {
      if (provider.license_number != null) {
        return provider.license_number;
      } else { return '-'; }
    },
    toggle(topic: number) {
      if (!this.selectedTopics.includes(topic)) {
        if (this.selectedTopics.length < 3) {
          this.selectedTopics.push(topic);
        }
      } else {
        this.selectedTopics.splice(this.selectedTopics.indexOf(topic), 1);
      }
    },
    isTopicSelected(topic: number) {
      return this.selectedTopics.includes(topic);
    },
    getProviderList() {
      const url: string = `${process.env.VUE_APP_API_LOCATION}/public/provider-profiles`;
      return new Promise((resolve, reject) => {
        axios.get(url, {
          headers: {
            'x-api-key' : 'R4EEpUGKy63kS8LGAHtf',
          },
        })
          .then((response: any) => {
            if (response.status !== 200) {
              reject(response);
            } else {
              this.providerList = response.data.data;
              resolve(response);
            }
          })
          .catch((error: any) => {
            reject(error);
          });
      });

    },
  },
  watch : {
  },
  mounted() {
    import('aos').then(
        AOS => AOS.init()
      ),
    Promise.all([this.getProviderList()])
      .then((values) => {
        this.ProvidersIterationInterval = window.setInterval(this.shiftProviders, 10000);
        this.ProviderSwiper.on('slideChangeTransitionEnd', () => {
          this.providerIndex = this.ProviderSwiper.realIndex;
        });

        this.TypeHelpIterationInterval = window.setInterval(this.shiftTypeHelps, 10000);
        this.TypeHelpSwiper.on('slideChangeTransitionEnd', () => {
          this.typeHelpIndex = this.TypeHelpSwiper.realIndex;
        });
      })
      .catch((error) => {
        return error;
      });

  },
  beforeDestroy() {
    window.clearInterval(this.ProvidersIterationInterval);
    window.clearInterval(this.TypeHelpIterationInterval);
  },
  components: {
    NavBar,
    Footer,
    swiper,
    swiperSlide,
    Modal,
  },
});
