export default (value: string) => {
  const trimValue = value.replace(/\s/g, '');
  const split = trimValue.split('/');

  if (trimValue.length !== 5) {
    return false;
  }

  if (split[0].length !== 2 || split[1].length !== 2) {
    return false;
  }

  if (parseInt(split[0], 10) > 12 || parseInt(split[0], 10) < 1) {
    return false;
  }

  if (parseInt(split[1], 10) < 17) {
    return false;
  }

  if (!(/^[0-9]*$/.test(split[0])) || !(/^[0-9]*$/.test(split[1]))) {
    return false;
  }

  return true;
};
