































































































































import store from '../store/index';
import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  props: {
  },
  data() {
    return {
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
    };
  },
  methods: {
    switchLang() {
        let langToChange: string = 'en';
        if (this.currentLang === 'en') {
            langToChange = 'th';
        }
        store.dispatch('lang/change', langToChange);
    },
  },
  computed: {
    currentLang() {
        return (store.state as any).lang.language;
    },
    langToChange() {
        if (this.currentLang === 'th') {
            return 'en';
        } else {
            return 'ไทย';
        }
    },
  },

});
