



























































































































































































// require styles
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '../components/NavBar.vue';
import Footer from '../components/Footer.vue';
import Modal from '../components/Modal.vue';
import 'aos/dist/aos.css';

export default Vue.extend({
  data(): {
    showVideoModal: boolean,
    query: object,
    webAppUrl: string,
  } {
    return {
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
      showVideoModal: false,
      query : {},
    };
  },
  computed: {
    goToApp(): string {
      const str = `${this.webAppUrl}/user/register?`;
      const query: any = this.query;
      return str + Object.keys(query).map((key) => {
        return key + '=' + query[key];
      }).join('&');
    },
  },
  methods: {},
  mounted() {
    import('aos').then(
        AOS => AOS.init()
      )
    if (this.$route.query) {
      const query = this.$route.query;
      if (query.email) {
        this.query = Object.assign({}, this.query, { email : query.email});
      }
      if (query.email_verification_token) {
        this.query = Object.assign({}, this.query, { email_verification_token : query.email_verification_token});
      }
    }
  },
  components: {
    NavBar,
    Footer,
    Modal,
  },
  // metaInfo: {
  //   links: [
  //     {rel: 'canonical', href: 'https://ooca.co'},
  //   ],
  // },
});
