








































































































































import store from '@/store';
import { Component, Prop , Vue } from 'vue-property-decorator';

export default Vue.extend({
  props: {
    bgColor: {
      type: String,
    },
    disableMenu: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    navMenuOpen: boolean,
    serviceSubMenuOpen: boolean,
    scrolled: boolean,
    webAppUrl: string,
  } {
    return {
      navMenuOpen: false,
      serviceSubMenuOpen: false,
      scrolled: false,
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
    };
  },
  methods: {
    switchLang() {
      let langToChange: string = 'en';
      if (this.currentLang === 'en') {
        langToChange = 'th';
      }
      store.dispatch('lang/change', langToChange);
    },
    openNavMenu() {
      this.navMenuOpen = true;
    },
    closeNavMenu() {
      this.navMenuOpen = false;
    },
    toggleServiceSubMenu() {
      this.serviceSubMenuOpen = !this.serviceSubMenuOpen;
    },
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    navMenuOpen(val) {
      const el = document.body;
      if (val) {
        el.classList.add('overflow-hidden');
      } else {
        el.classList.remove('overflow-hidden');
      }
    },
  },
  computed: {
    // webAppUrl() {
    //   return process.env.VUE_APP_WEB_APP_LOCATION;
    // },
    isShowMenu(): boolean {
      return !this.disableMenu;
    },
    currentLang() {
      return (store.state as any).lang.language;
    },
    langToChange() {
      if (this.currentLang === 'th') {
        return 'en';
      } else {
        return 'ไทย';
      }
    },
    colorNavbar() {
      if (!this.bgColor) {
        if (this.scrolled) {
          return 'color-scrolled';
        } else {
          return 'color-unscrolled';
        }
      }
    },
  },
});
