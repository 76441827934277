export default {
  en: {
    provider: {
      hero: {
        title: 'Let’s change the world',
        subTitle: 'with better mental wellness together.',
        callToAction: 'join us now',
      },
      explain: {
        firstSentence: 'better access with trustable platform',
        secondSentence: 'earn more form the comfort of home',
      },
      benefit: {
        title: 'benefit of ooca',
        subTitle:
          'Join Ooca as a provider to get new clients and work at' +
          ' the leading edge of tele-mental health innovation',
        work: 'work from home',
        schedule: 'set your schedule',
        revenue: 'incremental revenue',
        patient: 'patient satisfaction',
      },
      requirement: {
        title: 'requirements',
        subTitle:
          'You must be a psychiatrist, psychologist, counsellor willing to undergo a' +
          ' license confirmation and court background check.' +
          ' You must have the following qualifications to work with Ooca:',
        lists: {
          1: 'Licensed within Thailand or Internationally',
          2: 'Completed supervision hours and licensing exams',
          3: 'Provide professional reference person’s contact',
        },
      },
      application: {
        title: 'application process',
        subTitle:
          'Complete our application form which includes questions about your experience,' +
          ' your interests, and your counseling expertise. If you qualify, you will receive an email' +
          ' with instructions on how use our platform.',
        lists: {
          1: 'Application review process will take 5-7 days',
        },
        applyNow: 'Apply Now',
      },
      doing: {
        title: 'how are our providers doing?',
        quotes: {
          sukamon:
            'การไปพบจิตแพทย์ที่โรงพยาบาล บางรายเสียค่าใช้จ่ายในการเดินทาง' +
            'มากกว่าค่าบริการเสียอีก นี่ยังไม่นับเวลาที่เสียไป' +
            ' Ooca จึงช่วยลดภาระในเรื่องนี้',
          pimploy:
            'ทำงานในเส้นทางนี้มาสักพักใหญ่ มันทำให้เห็นว่ายังมีคนอีกกลุ่มนึงไม่กล้าไปพบจิตแพทย์ ' +
            'บางคนไม่มีเวลาต้องทำงานทุกวัน ต้องขอบคุณอูก้าที่ให้โอกาสได้ช่วยกลุ่มคนเหล่านั้น ' +
            'ได้มีพื้นที่ในการดูแลจิตใจตัวเอง',
          korboon:
            'ขอบคุณอูก้า แอปพลิเคชันระบบผู้เชี่ยวชาญให้การปรึกษาออนไลน์' +
            ' ที่ไม่เพียงมุ่งมั่นช่วยแก้ปัญหาและสนับสนุนช่วยเหลือจิตใจ' +
            'และการทำงาน อูก้ายังใส่ใจพัฒนาคุณภาพการให้บริการอย่างต่อเนื่อง ' +
            'เพื่อความสุขและคุณภาพชีวิตของคนไทยในวันนี้และวันข้างหน้า',
          methinee:
            'ตั้งแต่เข้ามาเป็นส่วนหนึ่งของอูก้า ทุก ๆ ครั้งที่จบงาน ก็มักจะยิ้มกับตัวเองเสมอ ๆ ว่า' +
            ' วันนี้เราได้ช่วยให้ใครคนหนึ่งยิ้มได้ ลุกได้ รักตัวเองได้ เพียงเท่านี้ ความสุข..ก็ถูกเชิญมานั่งในใจแล้ว',
          kittisak:
            'ด้วยความคิดสร้างสรรค์ในการประยุกต์ใช้เทคโนโลยีที่ก้าวหน้าของอูก้าได้ช่วย' +
            'เชื่อมโยงผู้คนและนักจิตวิทยาให้ใกล้ชิดกันมากขึ้น ทำให้คนมากมายได้หันกลับมาดูแลรักษาหัวใจของตนเอง',
          varangkana:
            'สภาพแห่งการแข่งขันและเร่งรีบของคนยุคปัจจุบัน ทำให้ผู้คนเผชิญสภาวะเครียด ' +
            'การได้พูดคุยกับใครสักคนที่สามารถไว้ใจได้ จึงเป็นหนทางระบายความทุกข์ ความหนักใจ' +
            ' ให้ใจได้เกิดความผ่อนคลาย และมีความพร้อมที่จะเผชิญสิ่งต่างๆ ต่อไป จากประสบการณ์' +
            'การเป็นนักจิตวิทยาการปรึกษาจึงพบว่า อูก้าตอบโจทย์ของการดูแลสุขภาพใจ ด้วยการพบนักจิตวิทยา' +
            'ที่ไหนก็ได้ เวลาไหนก็ได้ที่คุณพร้อม',
          suleeporn:
            ' ในบางช่วงเวลา คนเราก็แค่.. ต้องการใครสักคนที่รับฟัง ยอมรับ อบอุ่น และเป็นพื้นที่ปลอดภัย' +
            ' เพื่อเราจะได้ทำความเข้าใจตนเองในแบบที่เป็นอยู่จริง และค้นพบพลังที่จำเป็นสำหรับการรับมือกับ' +
            'ความท้าทายในชีวิตอย่างสง่างาม ซึ่งทีมงานจาก Ooca ก็ได้รับการฝึกฝนเรื่องนี้มาเป็นอย่างดี',
          nopphasit:
            'ดีใจที่อูก้าเป็นส่วนหนึ่งในการช่วยแบ่งเบาความทุกข์ในใจของผู้คนผ่านเทคโนโลยีที่ทันยุคสมัย',
          yuratchar:
            'การเข้าถึงบริการทางจิตเวช ในระบบรพ.ส่วนใหญ่จะเน้นหนักไปในด้านผู้ป่วยด้อยโอกาส ' +
            'ขาดความรู้ในด้านนี้ แต่จากการทำงานในระบบของ Ooca ทำให้พบว่ายังมีผู้ป่วยอีกกลุ่ม ' +
            'ที่เข้าไม่ถึงระบบบริการด้วยสาเหตุอื่นๆอีกมากมาย เช่น ไม่มีเวลา หรือไม่กล้าเข้าพบจิตแพทย์โดยตรง' +
            ' นับว่า Ooca ได้ให้โอกาสแก่ผู้ป่วยกลุ่มนี้ในการบำบัดรักษา',
        },
        name: {
          sukamon: 'Dr. Sukamon Wipaweeponkul, Psychiatrist',
          pimploy: 'Pimploy Rungsang, Psychologist',
          korboon: 'Korboon Klaotakan, Psychologist',
          methinee: 'Methinee Imduaisuk, Psychologist',
          kittisak: 'Kittisak Raksachart, Psychologist',
          varangkana: 'Dr. Varangkana Somanandana, Psychologist',
          suleeporn: 'Suleeporn Paramaputi, Psychologist',
          nopphasit: 'Nopphasit Sirijaroonchai, Psychiatrist',
          yuratchar: 'Dr. Yuratchar Preechar, Psychiatrist',
        },
      },
      apply: {
        title: 'already applied?',
        text1:
          'If your provider account have been confirmed and activated' +
          ' please read our guidline and tutorials here',
        manualLink: 'Ooca provider manual',
        text2: 'or please contact us if you have questions about application',
      },
      getStarted: {
        title: 'ready to change the mental wellness world with us?',
        signIn: 'sign in',
        register: 'apply now',
      },
    },
  },
  th: {
    provider: {
      hero: {
        title: 'ร่วมกันสร้างโลกที่ดีขึ้น',
        subTitle: 'ด้วยบริการปรึกษาออนไลน์ที่ใครๆก็เข้าถึงได้',
        callToAction: 'สมัครเลย',
      },
      explain: {
        firstSentence: 'better access with trustable platform',
        secondSentence: 'earn more form the comfort of home',
      },
      benefit: {
        title: 'ทำไมต้องร่วมงานกับเรา',
        subTitle:
          'ร่วมงานกับอูก้า เป็นหนึ่งในผู้ให้บริการปรึกษาออนไลน์' +
          ' เข้าถึงลูกค้าใหม่ๆ จากที่ไหนก็ได้ เมื่อไรก็ได้ ตามที่คุณสะดวก',
        work: 'ทำงานได้ที่บ้านคุณ',
        schedule: 'เลือกเวลาที่สะดวกเองได้',
        revenue: 'รายได้ตามผลการทำงาน',
        patient: 'ลูกค้าพึงพอใจ',
      },
      requirement: {
        title: 'ข้อกำหนดในการสมัคร',
        subTitle:
          'คุณต้องเป็นจิตแพทย์หรือนักจิตวิทยาที่มีใบอนุญาตหรือได้รับการรับรอง และยินยอมให้ทาง ' +
          'อูก้าตรวจสอบประวัติ โดยต้องมีคุณสมบัติเบื้องต้นดังนี้',
        lists: {
          1: 'มีใบอนุญาตหรือได้รับการรับรองในไทยหรือจากต่างประเทศ',
          2: 'มีประสบการณ์การให้คำปรึกษาหรือผ่านการรับรอง',
          3: 'มีบุคคลรับรองการทำงานที่สามารถติดต่อได้',
        },
      },
      application: {
        title: 'ขั้นตอนการพิจารณา',
        subTitle:
          'กรุณากรอกแบบฟอร์มสมัครเป็นผู้ให้บริการปรึกษาของอูก้า ' +
          'โดยจะมีคำถามเกี่ยวกับประสบการณ์การให้คำปรึกษา ประวัติการเรียนและการทำงานของคุณ ' +
          'คุณจะได้รับอีเมล์ยืนยันและคู่มือการใช้งานระบบ หากคุณผ่านการพิจารณาemail',
        lists: {
          1: 'กระบวนการพิจารณาจะใช้เวลา 5-7 วันทำการ',
        },
        applyNow: 'สมัครเลย',
      },
      doing: {
        title: 'ผู้ให้คำปรึกษาของเรา',
        quotes: {
          sukamon:
            'การไปพบจิตแพทย์ที่โรงพยาบาล บางรายเสียค่าใช้จ่ายในการเดินทาง' +
            'มากกว่าค่าบริการเสียอีก นี่ยังไม่นับเวลาที่เสียไป' +
            ' Ooca จึงช่วยลดภาระในเรื่องนี้',
          pimploy:
            'ทำงานในเส้นทางนี้มาสักพักใหญ่ มันทำให้เห็นว่ายังมีคนอีกกลุ่มนึงไม่กล้าไปพบจิตแพทย์ ' +
            'บางคนไม่มีเวลาต้องทำงานทุกวัน ต้องขอบคุณอูก้าที่ให้โอกาสได้ช่วยกลุ่มคนเหล่านั้น ' +
            'ได้มีพื้นที่ในการดูแลจิตใจตัวเอง',
          korboon:
            'ขอบคุณอูก้า แอปพลิเคชันระบบผู้เชี่ยวชาญให้การปรึกษาออนไลน์' +
            ' ที่ไม่เพียงมุ่งมั่นช่วยแก้ปัญหาและสนับสนุนช่วยเหลือจิตใจ' +
            'และการทำงาน อูก้ายังใส่ใจพัฒนาคุณภาพการให้บริการอย่างต่อเนื่อง ' +
            'เพื่อความสุขและคุณภาพชีวิตของคนไทยในวันนี้และวันข้างหน้า',
          methinee:
            'ตั้งแต่เข้ามาเป็นส่วนหนึ่งของอูก้า ทุก ๆ ครั้งที่จบงาน ก็มักจะยิ้มกับตัวเองเสมอ ๆ ว่า' +
            ' วันนี้เราได้ช่วยให้ใครคนหนึ่งยิ้มได้ ลุกได้ รักตัวเองได้ เพียงเท่านี้ ความสุข..ก็ถูกเชิญมานั่งในใจแล้ว',
          kittisak:
            'ด้วยความคิดสร้างสรรค์ในการประยุกต์ใช้เทคโนโลยีที่ก้าวหน้าของอูก้าได้ช่วย' +
            'เชื่อมโยงผู้คนและนักจิตวิทยาให้ใกล้ชิดกันมากขึ้น ทำให้คนมากมายได้หันกลับมาดูแลรักษาหัวใจของตนเอง',
          varangkana:
            'สภาพแห่งการแข่งขันและเร่งรีบของคนยุคปัจจุบัน ทำให้ผู้คนเผชิญสภาวะเครียด ' +
            'การได้พูดคุยกับใครสักคนที่สามารถไว้ใจได้ จึงเป็นหนทางระบายความทุกข์ ความหนักใจ' +
            ' ให้ใจได้เกิดความผ่อนคลาย และมีความพร้อมที่จะเผชิญสิ่งต่างๆ ต่อไป จากประสบการณ์' +
            'การเป็นนักจิตวิทยาการปรึกษาจึงพบว่า อูก้าตอบโจทย์ของการดูแลสุขภาพใจ ด้วยการพบนักจิตวิทยา' +
            'ที่ไหนก็ได้ เวลาไหนก็ได้ที่คุณพร้อม',
          suleeporn:
            ' ในบางช่วงเวลา คนเราก็แค่.. ต้องการใครสักคนที่รับฟัง ยอมรับ อบอุ่น และเป็นพื้นที่ปลอดภัย' +
            ' เพื่อเราจะได้ทำความเข้าใจตนเองในแบบที่เป็นอยู่จริง และค้นพบพลังที่จำเป็นสำหรับการรับมือกับ' +
            'ความท้าทายในชีวิตอย่างสง่างาม ซึ่งทีมงานจาก Ooca ก็ได้รับการฝึกฝนเรื่องนี้มาเป็นอย่างดี',
          nopphasit:
            'ดีใจที่อูก้าเป็นส่วนหนึ่งในการช่วยแบ่งเบาความทุกข์ในใจของผู้คนผ่านเทคโนโลยีที่ทันยุคสมัย',
          yuratchar:
            'การเข้าถึงบริการทางจิตเวช ในระบบรพ.ส่วนใหญ่จะเน้นหนักไปในด้านผู้ป่วยด้อยโอกาส ' +
            'ขาดความรู้ในด้านนี้ แต่จากการทำงานในระบบของ Ooca ทำให้พบว่ายังมีผู้ป่วยอีกกลุ่ม ' +
            'ที่เข้าไม่ถึงระบบบริการด้วยสาเหตุอื่นๆอีกมากมาย เช่น ไม่มีเวลา หรือไม่กล้าเข้าพบจิตแพทย์โดยตรง' +
            ' นับว่า Ooca ได้ให้โอกาสแก่ผู้ป่วยกลุ่มนี้ในการบำบัดรักษา',
        },
        name: {
          sukamon: 'นพ. สุกมล	วิภาวีพลกุล, จิตแพทย์',
          pimploy: 'พิมพ์พลอย รุ่งแสง, นักจิตวิทยา',
          korboon: 'กอบุญ เกล้าตะกาญจน์, นักจิตวิทยา',
          methinee: 'เมทินี อิ่มด้วยสุข, นักจิตวิทยา',
          kittisak: 'กิตติศักดิ์ รักษาชาติ, นักจิตวิทยา',
          varangkana: 'ดร.วรางคณา  โสมะนันทน์, นักจิตวิทยา',
          suleeporn: 'ศุลีพร ปรมาภูติ, นักจิตวิทยา',
          nopphasit: 'นพสิทธิ์ สิริจรูญชัย, จิตแพทย์',
          yuratchar: 'แพทย์หญิง ยุรัชชา ฤทธิชัย, จิตแพทย์',
        },
      },
      apply: {
        title: 'เคยส่งแบบฟอร์มสมัครแล้ว?',
        text1:
          'หากการสมัครของคุณผ่านการพิจารณาแล้ว บัญชีผู้ให้คำปรึกษาของคุณจะได้รับการยืนยัน ' +
          'โปรดอ่านคู่มือการใช้งานเพื่อเริ่มใช้งานอูก้า',
        manualLink: 'คู่มือการใช้งานสำหรับผู้ให้คำปรึกษา',
        text2:
          'หากพบปัญหาเกี่ยวกับการสมัครเป็นผู้ให้คำปรึกษา คุณสามารถติดต่อเราได้ที่',
      },
      getStarted: {
        title: 'คุณพร้อมที่จะเปลี่ยนโลกไปกับเราหรือยัง?',
        signIn: 'เข้าสู่ระบบ',
        register: 'สมัครเลย',
      },
    },
  },
};
