export default {
  namespaced: true,
  state: {
    language: 'th',
  },
  mutations: {
    CHANGE_LANGUAGE(state: any, lang: string) {
      localStorage.setItem('language', lang);
      state.language = lang;
      (window as any).Vue.$options.i18n.locale = lang;
    },
  },
  actions: {
    change({ commit }: any, lang: string) {
      commit('CHANGE_LANGUAGE', lang);
    },
  },
  getters: {
    current(state: any) {
      return state.language;
    },
  },
};
