export default {
  en: {
    privacy: {
      title : 'Privacy Policy',
      url:'https://user-contents.ooca.co/statics/privacy-policies/en.html'
    },
  },
  th: {
    privacy: {
      title : 'นโยบายความเป็นส่วนตัว',
      url:'https://user-contents.ooca.co/statics/privacy-policies/th.html'
    },
  },
};
