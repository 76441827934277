/* ============
 * Number Util
 * ============
 */

export default {
  comma(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  commaWithDecimal(x: any, toFixed: number = 2) {
    if (x) {
      x = x.toFixed(toFixed);
      const parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    } else { return '0.00'; }
  },

  roundUp(value: any, precision: any) {
    const pow = Math.pow(10, precision);
    return (
      (Math.ceil(pow * value) +
        Math.ceil(pow * value - Math.ceil(pow * value))) /
      pow
    );
  },
};
