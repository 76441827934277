export default {
  en: {
    validation: {
      voucher: {
        fromFirstname: {
          maxLength: 'The first name is too long.',
          fallback: 'Please enter your first name.',
        },
        fromLastname: {
          maxLength: 'The last name is too long.',
          fallback: 'Please enter your last name.',
        },
        fromEmail: {
          email: 'Invalid email format.',
          maxLength: 'The email address is too long.',
          fallback: 'Please enter your email address.',
        },
        fromTel: {
          tel: 'Invalid tel format.',
          maxLength: 'The tel is too long.',
          fallback: 'Please enter your tel.',
        },
        toName: {
          maxLength: 'The name is too long',
          fallback: 'Please enter the recipient\'s name.',
        },
        toEmail: {
          email: 'Invalid email format.',
          maxLength: 'The email address is too long.',
          fallback: 'Please enter the recipient\'s email address.',
        },
        toTel: {
          tel: 'Invalid tel format.',
          maxLength: 'The tel is too long.',
          fallback: 'Please enter your tel.',
        },
        message: {
          maxLength: 'The message is too long.',
          fallback: 'Please enter a message for the recipient.',
        },
        cardholderName: {
          maxLength: 'The card holder name is too long.',
          fallback: 'Please enter the card holder name',
        },
        creditCard: {
          creditCard: 'Invalid credit card format.',
          fallback: 'Please enter credit card number.',
        },
        expire: {
          expireCard: 'Invalid expiration format.',
          fallback: 'Please enter card expiration.',
        },
        cvv: {
          required: 'Please enter CVV.',
          fallback: 'Invalid CVV format.',
        },
      },
    },
  },
  th: {
    validation: {
      voucher: {
        fromFirstname: {
          maxLength: 'ชื่อยาวเกินไป',
          fallback: 'กรุณาใส่ชื่อ',
        },
        fromLastname: {
          maxLength: 'นามสกุลยาวเกินไป',
          fallback: 'กรุณาใส่นามสกุล',
        },
        fromEmail: {
          email: 'รูปแบบอีเมลไม่ถูกต้อง',
          maxLength: 'อีเมลยาวเกินไป',
          fallback: 'กรุณาใส่อีเมล',
        },
        fromTel: {
          tel: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง',
          maxLength: 'เบอร์โทรศัพท์ยาวเกินไป',
          fallback: 'กรุณาใส่เบอร์โทรศัพท์',
        },
        toName: {
          maxLength: 'ชื่อยาวเกินไป',
          fallback: 'กรุณาใส่ชื่อผู้รับ',
        },
        toEmail: {
          email: 'รูปแบบอีเมลไม่ถูกต้อง',
          maxLength: 'อีเมลยาวเกินไป',
          fallback: 'กรุณาใส่อีเมลผู้รับ',
        },
        toTel: {
          tel: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง',
          maxLength: 'เบอร์โทรศัพท์ยาวเกินไป',
          fallback: 'กรุณาใส่เบอร์โทรศัพท์',
        },
        message: {
          maxLength: 'ข้อความยาวเกินไป',
          fallback: 'กรุณาเขียนข้อความถึงผู้รับ',
        },
        cardHolderName: {
          maxLength: 'ชื่อผู้ถือบัตรยาวเกินไป',
          fallback: 'กรุณาใส่ชื่อผู้ถือบัตร',
        },
        creditCard: {
          creditCard: 'รูปแบบเลขบัตรเครดิตไม่ถูกต้อง',
          fallback: 'กรุณาใส่เลขบัตรเครดิต',
        },
        expire: {
          expireCard: 'รูปแบบเดือนบัตรหมดอายุไม่ถูกต้อง',
          fallback: 'กรุณาใส่เดือนบัตรหมดอายุ',
        },
        cvv: {
          required: 'กรุณาใส่ CVV',
          fallback: 'รูปแบบ CVV ไม่ถูกต้อง',
        },
      },
    },
  },
};
