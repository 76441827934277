export default {
  en: {
    corporate: {
      hero: {
        title: 'Ooca for Corporates',
        subtitle: 'The Tele-mental Health Platform to support your team.',
        callToAction: 'Contact Us',
        howToUse: 'How to Use Corporate',
      },
      solutions: {
        title: 'Solution for your team’s well-being',
        subtitle: 'Ooca for Corporates connected HR and employees efficiently,' +
        'most employees are having mental well-being problems in workplace but too afraid to ask for help.',
        tracking: 'Tracking team wellness through time with measurable outcome',
        satisfaction: 'Increase team’s satisfaction and productivity',
        stress: 'Spot diagnosis to your organization stress and burn-outs',
      },
      benefits: {
        title: 'Retain your talent and increase their happiness with ooca for corporates',
        turnOver: {
          title: 'Reduce turnover',
          text: '75% of employees are stress and looking for a new job.',
        },
        lowerCosts: {
          title: 'Lower costs',
          text: 'well-being problems in the workplace cost employers up to $500 billion annually',
        },
        engagement: {
          title: 'Increase engagement',
          text: 'Over 75% of companie’s EAP are ignored with less than 3% of employees using it.',
        },
      },
      clients: {
        title: 'Our clients',
      },
      features: {
        title: 'Let Ooca support your team',
        subtitle: 'Understand your team\'s insight, keep them company when things are rough.',
        counseling: {
          title: 'counseling service',
        },
        wellnessTest: {
          title: 'wellness profile test',
        },
        report: {
          title: 'statistics and report',
        },
      },
      analytics: {
        title: 'What can we tell?',
        subtitle: 'Understand team’s insights through our anonymized real-time data analytic panel',
        departments: 'Department in risk for mental crisis',
        topics: 'Topic of concerns of your employee',
        progress: 'See progress and trends of mental wellness in your organization',
      },
      customization: {
        title: 'Customization for corporates',
        subtitle: 'Customized splash screen with privileges display and adjustable limitation usage',
        contact: {
          text: 'Need further information? book a demo session with us!',
          btn: 'Contact sales',
        },
      },
      packageCalculator: {
        title: 'Package calculator',
        subtitle: 'Find the right package that perfectly match your team',
        headcount: 'Analytic Headcount',
        sessions: 'Counseling Sessions',
        packages: {
          s: 'Package S',
          sPlus: 'S+',
          m: 'Package M',
          mPlus: 'M+',
          l: 'Package L',
          lPlus: 'L+',
          plusPlus: '+++++',
        },
      },
      getStarted: {
        title: 'Ready to get started?',
        subtitle: 'Let Ooca take care of your team.',
        btnGetStarted: 'Get started',
        btnContactSales: 'Contact sales',
      },
    },
  },
  th: {
    corporate: {
      hero: {
        title: 'อูก้าสำหรับองค์กร',
        subtitle: 'แพลตฟอร์มดูแลปัญหาด้านจิตใจเพื่อพนักงานของคุณ',
        callToAction: 'ติดต่อเรา',
        howToUse: 'วิธีใช้งานสำหรับพนักงานในองค์กร',
      },
      solutions: {
        title: 'ความสุขของพนักงานคือความสุขของคุณ',
        subtitle: 'คนส่วนใหญ่มีปัญหาความเครียดจากการทำงาน ' +
        'แต่ไม่กล้าบอกใคร อูก้าช่วยให้บริษัทและพนักงานเข้าใจกันมากกว่าที่เคย',
        tracking: 'ติดตามภาพรวมสุขภาวะของพนักงานอย่างเป็นรูปธรรมและวัดผลได้',
        satisfaction: 'ความสุขและประสิทธิภาพในการทำงานของพนักงานเพิ่มขึ้น',
        stress: 'เห็นภาพรวมความเครียดและปัญหาภายในองค์กร เพื่อป้องกันและบรรเทา',
      },
      benefits: {
        title: 'เพิ่มความสุข เพิ่มอายุการทำงาน',
        turnOver: {
          title: 'ลาออกน้อยลง',
          text: '75% ของพนักงานมีปัญหาความเครียดจากการทำงานและมองหางานที่ดีกว่าอยู่ตลอดเวลา',
        },
        lowerCosts: {
          title: 'ลดค่าใช้จ่าย',
          text: 'ปัญหาสุขภาวะในที่ทำงานสร้างความเสียหายให้กับนายจ้างกว่า 15 พันล้านบาทต่อปี ',
        },
        engagement: {
          title: 'เพิ่มความร่วมมือ',
          text: 'กว่า 75% ของโครงการช่วยเหลือพนักงานในองค์กร มีพนักงานได้ใช้จริงเพียง 3%',
        },
      },
      clients: {
        title: 'ลูกค้าองค์กร',
      },
      features: {
        title: 'ให้อูก้าช่วยดูแลทีมของคุณ',
        subtitle: 'บริการของอูก้าช่วยให้คุณเข้าอกเข้าใจพนักงานและอยู่เคียงข้างเขาในเวลาที่ยากลำบาก',
        counseling: {
          title: 'ปรึกษาจิตแพทย์ออนไลน์',
        },
        wellnessTest: {
          title: 'แบบทดสอบความเครียด',
        },
        report: {
          title: 'รายงานสถิติการใช้งาน',
        },
      },
      analytics: {
        title: 'อูก้าบอกอะไรได้บ้าง',
        subtitle: 'เข้าใจความต้องการที่แท้จริงของพนักงานด้วยระบบรายงานผลการใช้งานอย่างไม่ระบุตัวตน',
        departments: 'บอกได้ว่าพนักงานแผนกไหนอยู่ในกลุ่มเสี่ยงมีปัญหาสุขภาพจิต',
        topics: 'ปัญหาอะไร หรือประเด็นไหนที่กำลังกวนใจพนักงานมากที่สุด',
        progress: 'ติดตามปัจจัยและแนวโน้มสุขภาวะทางจิตภายในองค์กรของคุณอย่างแม่นยำ',
      },
      customization: {
        title: 'ปรับแต่งได้ตามความต้องการขององค์กร',
        subtitle: 'เลือกได้หมด ตั้งแต่จำนวนการใช้งานที่เหมาะสมกับขนาดของทีม ' +
          'จนถึงหน้าตาแอพฯที่ตรงกับอัตลักษณ์องค์กรของคุณ',
        contact: {
          text: 'ต้องการข้อมูลเพิ่มเติม? ติดต่อเราเพื่อทดลองใช้ได้เลย',
          btn: 'ติดต่อเรา',
        },
      },
      packageCalculator: {
        title: 'Package calculator',
        subtitle: 'Find the right package that perfectly match your team',
        headcount: 'Analytic Headcount',
        sessions: 'Counseling Sessions',
        packages: {
          s: 'Package S',
          sPlus: 'S+',
          m: 'Package M',
          mPlus: 'M+',
          l: 'Package L',
          lPlus: 'L+',
          plusPlus: '+++++',
        },
      },
      getStarted: {
        title: 'พร้อมเริ่มใช้งานอูก้าหรือยัง?',
        subtitle: 'ให้อูก้าช่วยดูแลคุณหรือคนที่คุณรัก',
        btnGetStarted: 'เริ่มใช้งานเลย',
        btnContactSales: 'ติดต่อเรา',
      },
    },
  },
};
