export default {
    en: {
        chevron: {
            hero: {
                title: 'EAP Worklife Services<br><br>EAP Worklife Services offer psychological counselling service by' +
                    'professional psychologists for Chevron employees and families.',
                dialog: '\tOur services are a new counselling service for '+
                'promoting better mental health that can be easily '+
                'accessed through modern digital channel anywhere, '+
                'any time as you prefer. Most importantly, your privacy '+
                'is also securely protected; therefore, your private '+
                'information will be kept confidential',
            },
            whatOoca: {
                title: 'What is Ooca?',
                subTitle: 'Ooca is a telemedicine platform that helps ' +
                    'you connect with psychologists via video calls. ' +
                    'Ooca application is available on Appstore, Google ' +
                    'Play and web based Google Chrome browser ' +
                    'in the form of Web-app.',
            },
            whyOoca: {
                title: 'Why Ooca?',
                subTitle: 'Our psychological counselling provides service via ' +
                    'telemedicine platform and is adaptable per your preferences.',
                1: {
                    title: 'Anywhere',
                    subTitle: 'You can consult with a psychologist from your place or anywhere you see convenient',
                },
                2: {
                    title: 'Confidential',
                    subTitle: 'You can use your video call counselling service with ease in a highly confidential manner',
                },
                3: {
                    title: 'Time-saving',
                    subTitle: 'Instead of waiting in a long queue, you can choose your convenient date to consult with a psychologist',
                },
            },
            howToUse: {
                title: '***Watch a demo VDO: How Ooca works***',
                warning: 'Chevron subscription, you can use the company\'s computer via IE. In the registration procedure only.',
            },
            howToRegister: {
                title: 'How to get started?',
                subTitle: 'Signing up with Ooca prior to your first appointment',
                text1: 'Register by using your company email or personal email that has been posted (for family) ' +
                    'address via www.ooca.co or download ooca application at App Store or Google Play', 
                text2: 'Set up personal information (real name or anonymous)',
                text3: 'Get started using Ooca via application or call our Hotline service at 02-328-3988',
                note: {
                    title: '*Terms of service',
                    1: 'Our professional psychologists will be at your psychological counselling service via Ooca application and Hotline call',
                    2: 'If you have any questions or concerns, please contact our Customer Support at 090-004-0006 during 9.30 – 19.30 hrs.',
                    21: 'In addition to these hours, please leave a message and call back in Crisp (icon in the lower right corner), staff will call back as soon as possible.',
                    3: 'หากต้องการปรึกษาเร่งด่วน/ฉุกเฉิน สามารถใช้บริการผ่าน Hotline โทร 02-328-3988',
                },
            },
            guideline: {
                title: 'Counselling Step via video call',
                title2: 'Via 2 channels',
                step1: 'Download Ooca',
                step2: 'Make an appointment by select the provider and time.',
                step3: 'Start Counselling Video call',
                videoCall: {
                    title: '1. Video Call',
                    viaVideoCall: 'Video Call via Ooca application',
                    makeAppointment: 'To make an appointment<br>Select the desired time and psychologists.',
                    waiting: 'Please come in and wait in the application (<u>at waiting room</u>) Ahead of time, at least 10 minutes.',
                    call: 'Start counselling via Video Call',
                    ps: ' *Counselling service is provided by professional psychologist.' +
                        '<br> <u class="text-red">Any cancellations or postponements<br> must be made no less than 15 minutes prior <br>to the appointment time.</u>' +
                        '<br>Or else, your appointment will be fully charged to no avail.',
                },
                hotline: {
                    title: '2. Hotline 02-328-3988',
                    viaHotline: 'Through the hotline calls',
                    callTo: 'Call',
                    callTo2: 'with your registered phone number ONLY',
                    period: 'Hotline service hours of operation',
                    subPeriod: 'Can consult with a psychologist immediately',
                    ps: '  *Urgent cases: Can contact at any hours.' +
                        '<br/>*Emergency cases: In cases of having suicidal thoughtsassault attempt, hallucinations, Ooca will assess the conditions and proceed with proper management',
                },
            },
            tutorial: {
                1: {
                    title: 'Use Ooca Application/website',
                    subTitle:  'Sign up with your company email and password in Application/ Website',
                },
                2: {
                    title: 'Choose your counselling topic',
                    subTitle:  'Choose your preferred topic order to match you with the psychologist of that expertise.',
                },
                3: {
                    title: 'Select your preferred psychologist',
                    subTitle:  'Our team of psychologists are certified specialists and can be verified.',
                },
                4: {
                    title: 'Start your counselling session via Video Call',
                    subTitle:  'After scheduling an appointment. <u>Please come in and wait in the application on appointment time,</u> you can turn off your camera to consult anonymously.',
                },
            },
            goToRegister: {
                title: 'Exclusive offer for Chevron Employees only',
                warning: '**Please sign up /log in with your Chevron email address or email provided and use the password that you set up only **',
                button: 'Register',
            },
        },
    },
    th: {
        chevron: {
            hero: {
                title: 'โครงการ Chevron EAP Worklife Services<br> บริการให้คำปรึกษาด้านจิตใจ<br>โดยนักจิตวิทยา เพื่อพนักงานเชฟรอนและครอบครัว',
                dialog: '\tรูปแบบใหม่ของการให้คำปรึกษา เพื่อส่งเสริมให้พนักงานและครอบครัวทุกท่านมีสุขภาพใจที่แข็งแรง ผ่านช่องทางดิจิทัลที่สะดวกสบายและทันสมัย ในช่วงเวลาและ\n' +
                    'สถานที่ที่คุณเลือกได้เอง แต่ยังคงความเป็นส่วนตัว โดยข้อมูลทุกอย่างจะถูกเก็บรักษาไว้เป็นความลับ จึงสบายใจได้ว่าการปรึกษาของคุณจะไม่ถูกเปิดเผย',
            },
            whatOoca: {
                title: 'อูก้า คืออะไร',
                subTitle: 'Ooca เป็นแพลตฟอร์มที่ให้คำปรึกษาทางจิตวิทยา โดยนักจิตวิทยา' +
                    '<br/> ผ่าน แอพพลิเคชั่น ทั้ง iOS, Android และ Google chrome ในรูปแบบ Web-app',
            },
            whyOoca: {
                title: 'ทำไมต้องอูก้า',
                subTitle: 'เรายกระดับการปรึกษาปัญหาใจทางไกลเพื่อความพึงพอใจของคุณ',
                1: {
                    title: 'ไม่จำกัดสถานที่',
                    subTitle: 'คุณสามารถพูดคุยกับนักจิตวิทยาได้จากที่บ้านหรือที่ไหนก็ได้อย่างสะดวกสบาย',
                },
                2: {
                    title: 'มั่นใจได้ว่าเป็นความลับ',
                    subTitle: 'ระบบการปรึกษาผ่าน video call จะทำให้คุณรู้สึกสบายใจที่จะเข้าพบนักจิตวิทยา โดยไม่ต้องกังวลว่าจะมีคนรู้',
                },
                3: {
                    title: 'ไม่เสียเวลารอ',
                    subTitle: 'แทนที่จะต้องไปนั่งรอคิวที่โรงพยาบาล คุณสามารถนัดหมายล่วงหน้าในเวลาที่คุณสะดวกได้ และไม่ต้องเสียเวลาเดินทาง',
                },
            },
            howToUse: {
                title: '***วิดีโอแสดงขั้นตอนการใช้งานระบบอูก้า***',
                warning: 'สำหรับการสมัครสมาชิกของ Chevron ท่านสามารถใช้งานผ่าน IE ของคอมพิวเตอร์บริษัทได้แค่ขั้นตอนลงทะเบียนเท่านั้น',
            },
            howToRegister: {
                title: 'วิธีลงทะเบียนใช้บริการ',
                subTitle: 'ลงทะเบียนล่วงหน้าเพื่อความสะดวกในการใช้บริการ',
                text1: 'ลงทะเบียนด้วยอีเมลพนักงานหรืออีเมลส่วนตัวที่ได้แจ้งไว้(สำหรับครอบครัว) ผ่าน www.ooca.co หรือดาวน์โหลดแอปพลิเคชันผ่าน App store และ Google play',
                text2: 'กรอกข้อมูลส่วนตัวหรือใช้นามสมมติ',
                text3: 'เริ่มใช้บริการผ่าน แอปพลิเคชัน หรือ โทร Hotline 02-328-3988',
                note: {
                    title: '*หมายเหตุ',
                    1: 'การใช้งานผ่านแอปพลิเคชันอูก้า สามารถเลือกปรึกษากับนักจิตวิทยา',
                    2: 'หากพบข้อสงสัยหรือปัญหาในการใช้งาน กรุณาติดต่อ 090-004-0006 ติดต่อได้ในวันและเวลา 09.30 - 19.30 น.',
                    21: 'นอกเหนือจากเวลาดังกล่าวกรุณาฝากข้อความและเบอร์โทรกลับไว้ใน Crisp (ไอคอนมุมล่างขวา) เจ้าหน้าที่จะโทรติดต่อกลับโดยเร็วที่สุด',
                    3: 'หากต้องการปรึกษาเร่งด่วน/ฉุกเฉิน สามารถใช้บริการผ่าน Hotline โทร 02-328-3988',
                },
            },
            guideline: {
                title: 'ขั้นตอนการปรึกษาผ่าน Video Call',
                title2: 'ผ่าน 2 ช่องทาง',
                step1: 'ดาวโหลด Ooca',
                step2: 'ทำการนัดหมายเลือกหมอและเวลาที่ต้องการ',
                step3:'เริ่มการปรึกษาผ่าน Video call',
                videoCall: {
                    title: '1. Video Call',
                    viaVideoCall: 'ใช้งานผ่าน Application Ooca*',
                    makeAppointment: 'กดทำการนัดหมาย <br/>กดเลือกนักจิตวิทยาและเวลาที่ต้องการ',
                    waiting: 'กรุณาเข้ามารอในแอปพลิเคชัน(<u>ห้องรอตรวจ</u>)<br> ก่อนเวลานัดหมายอย่างน้อย 10 นาที ',
                    call: 'เริ่มการปรึกษาผ่าน Video call',
                    ps: ' *บริการให้คำปรึกษาโดยนักจิตวิทยา' +
                        '<br> <u class="text-red">หากท่านไม่สามารถรับการปรึกษาตามเวลาที่นัดหมายได้' +
                        '<br> โปรดแจ้งยกเลิกก่อนเวลานัดหมายไม่น้อยกว่า 15 นาที</u>' +
                        '<br> มิฉะนั้น Ooca จะทำการคิดมูลค่าการให้บริการให้คำปรึกษาในครั้งนั้น',
                },
                hotline: {
                    title: '2. Hotline 02-328-3988',
                    viaHotline: 'ใช้งานผ่านการโทร Hotline',
                    callTo: 'โทรไปที่เบอร์',
                    callTo2: 'ด้วยเบอร์มือถือที่ลงทะเบียนไว้เท่านั้น',
                    period: 'ช่วงเวลาการให้บริการ Hotline',
                    subPeriod: 'สามารถปรึกษากับนักจิตวิทยาได้ทันที',
                    ps: '  *กรณีปรึกษาเร่งด่วน - สามารถโทรปรึกษานักจิตวิทยาได้ทันที' +
                        '<br/>*กรณีฉุกเฉิน: ในเคสที่มีความคิดอยากฆ่าตัวตาย การพยายามทำร้ายร่างกายตนเอง' +
                        '<br/>และผู้อื่น, หูแว่ว ประสาทหลอน, อูก้าจะประเมินและจัดการอย่างเหมาะสม',
                },
            },
            tutorial: {
                1: {
                    title: 'ใช้งานผ่าน Ooca Application/Website',
                    subTitle:  'กรอกข้อมูลลงทะเบียนกับ Ooca ผ่านแอปพลิเคชันหรือเว็บไซต์ได้ตามที่คุณต้องการ',
                },
                2: {
                    title: 'เลือกหัวข้อที่ต้องการปรึกษา',
                    subTitle:  'เลือกหัวข้อเพื่อให้ได้รับคำปรึกษาที่ตรงที่สุด เพราะผู้ให้บริการแต่ละคนมีความเชี่ยวชาญแตกต่างกัน',
                },
                3: {
                    title: 'เลือกนักจิตวิทยาได้',
                    subTitle:  'คุณสามารถเลือกผู้ให้บริการที่เชี่ยวชาญและผ่านการตรวจสอบจากระบบได้ด้วยตนเอง',
                },
                4: {
                    title: 'พูดคุยผ่าน Video Call',
                    subTitle:  'หลังจากทำการนัดหมายแล้ว สามารถปรึกษาแพทย์ได้เมื่อถึงเวลานัด นอกจากนี้ยังสามารถปิดกล้องได้ หากต้องการปกปิดตัวตน',
                },
            },
            goToRegister: {
                title: 'พิเศษเฉพาะพนักงานในบริษัทเท่านั้น',
                warning: '** โปรดสมัครสมาชิกหรือเข้าสู่ระบบด้วยอีเมล์พนักงานบริษัท และpassword ที่ท่านตั้งขึ้นเท่านั้น **',
                button: 'ลงทะเบียน',
            },
        },
    },
};
