export default {
  en: {
    career: {
      hero: {
        title: 'Let’s change the world',
        subTitle: 'with better mental wellness together.',
        callToAction: 'join us now',
      },
      waiting: {
        title: 'we waiting for you',
        subTitle:
          'Join Ooca and working with the leading edge ' +
          'of innovation in the mental health field.',
        name: {
          1: 'Kanpassorn Suriyasangpetch',
          2: 'Nattanit Bua',
          3: 'Korboon Klaotakan',
          4: 'Preeyaporn Jindapod',
          5: 'Jirasak Khananukul',
        },
        position: {
          1: 'CEO',
          2: 'UX',
          3: 'PSYCHOLOGIST',
          4: 'ASSISTANT',
          5: 'CTO',
        },
      },
      opening: {
        title: 'current openings',
        subTitle: 'We’re hiring for a number of different positions',
        career: {
          mobiledev: {
            name: 'Mobile developer <br>(Native iOS, Android)',
            // description:
            //   'Ooca is looking for an excellent Front-end developer ' +
            //   'to join our team. You will be responsible for the front-end development ' +
            //   'of various Ooca products, e.g. Ooca Website, Dashboard UI and many other ' +
            //   'exciting products. We are building a team that is continuously improving ' +
            //   'front-end systems which help make our clients’ lives easier.',
            responsibility: {
              // tslint:disable-next-line:max-line-length
              1: 'Translate concepts into user flows, wireframes, mockups and prototypes that lead to intuitive user experiences.',
              // tslint:disable-next-line:max-line-length
              2: 'Facilitate the client’s product vision by researching, conceiving, sketching, prototyping and user-testing experiences for digital products.',
              // tslint:disable-next-line:max-line-length
              3: 'Design and deliver wireframes, user stories, user journeys, and mockups optimized for a wide range of devices and interfaces.',
              4: 'Identify design problems and devise elegant solutions.',
              // tslint:disable-next-line:max-line-length
              5: 'Make strategic design and user-experience decisions related to core, and new, functions and features.',
              6: 'Take a user-centered design approach and rapidly test and iterate your designs.',
              7: 'Collaborate with other team members and stakeholders.',
              8: 'Ask smart questions, take risks and champion new ideas.',
            },
            requirement: {
              1: 'Proficient with Swift and Cocoa Touch',
              2: 'Experience with iOS frameworks such as Core Data, Core Animation, etc.',
              3: 'Experience with Android framework.',
              4: 'Experience with Kotlin is a preferred.',
              5: 'Familiarity with RESTful APIs to connect iOS and Android applications to back-end services',
              6: 'Experience with working with back-end framework such as Laravel, dJango or Flask',
              7: 'Knowledge of other web technologies and UI/UX standards',
              8: 'Understanding of design principles and interface guidelines',
              9: 'Familiarity with cloud message APIs and push notifications',
              10: 'Proficient understanding of code versioning using Git',
            },
            benefit: {
              1: 'Social Security Insurance + Health Insurance',
              2: 'Transportation fee when traveling',
              // 3: "Phone call fee with sim cards and a phone",
              // 4: "Commission",
              5: 'Free lunch',
            },
            whatYouGet: {
              1: 'Challenging work experience with opportunities to work with top-tier corporate clients',
              2: 'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
              3: 'Classes and training program at Thailand\'s top accelerator program',
            },
          },
          ux: {
            name: 'UX/UI Designer',
            // description:
            //   '25-35k',
            responsibility: {
              // tslint:disable-next-line:max-line-length
              1: 'Design both UX and UI for both mobile and website. We understand the differences of UX and UI but as a start-up company we need to move fast. To be both a great visual artist with user experience understanding will benefit our time meticulously.',
              // tslint:disable-next-line:max-line-length
              2: 'User research and analysis for UX improvement',
              // tslint:disable-next-line:max-line-length
              3: 'Loves talking to people, conducting user interview, gaining insights and able to set up objectives of the interview.',
              4: 'Collaborate with developers, stakeholders and CEO to create great design',
            },
            requirement: {
              1: 'Previous experience working with developers team and UX UI responsive design understanding',
              2: 'Fast learner and mover',
              3: 'Flexible and hardworking',
              4: 'Sketch or Abode XD User. We do not look for UX/UI that rely only on Photoshop and Illustrator',
              5: 'At least 2 years of experience',
            },
            benefit: {
              1: 'Social Security Insurance + Health Insurance',
              2: 'Transportation fee when traveling',
              // 3: "Phone call fee with sim cards and a phone",
              // 4: "Commission",
              5: 'Free lunch',
            },
            whatYouGet: {
              1: 'Challenging work experience with opportunities to work with top-tier corporate clients',
              2: 'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
              3: 'Classes and training program at Thailand\'s top accelerator program',
            },
          },
        },
        applyJob: 'apply for this job',
      },
      contactEmail: {
        title: 'Don\'t see the position you\'re looking for?',
        text: 'Contact us directly, we’ll get in touch',
      },
      getStarted: {
        title: 'ready to change the mental wellness world with us?',
        signIn: 'sign in',
        register: 'apply now',
      },
    },
  },
  th: {
    career: {
      hero: {
        title: 'ร่วมกันสร้างโลกที่ดีขึ้น',
        subTitle: 'ด้วยบริการปรึกษาออนไลน์ที่ใครๆก็เข้าถึงได้',
        callToAction: 'สมัครเลย',
      },
      waiting: {
        title: 'มาเป็นส่วนหนึ่งของครอบครัวอูก้า',
        subTitle:
          'ร่วมงานกับอูก้า เราคือแพลตฟอร์มให้คำปรึกษาออนไลน์แนวหน้าของไทย',
        name: {
          1: 'Kanpassorn Suriyasangpetch',
          2: 'Nattanit Bua',
          3: 'Korboon Klaotakan',
          4: 'Preeyaporn Jindapod',
          5: 'Jirasak Khananukul',
        },
        position: {
          1: 'CEO',
          2: 'UX',
          3: 'PSYCHOLOGIST',
          4: 'ASSISTANT',
          5: 'CTO',
        },
      },
      opening: {
        title: 'ตำแหน่งที่เปิดรับอยู่ ',
        subTitle: 'อูก้ากำลังเปิดรับเพื่อนร่วมงานหลายตำแหน่ง ',
        career: {
          mobiledev: {
            name: 'Mobile developer <br>(Native iOS, Android)',
            // description:
            //   'Ooca is looking for an excellent Front-end developer ' +
            //   'to join our team. You will be responsible for the front-end development ' +
            //   'of various Ooca products, e.g. Ooca Website, Dashboard UI and many other ' +
            //   'exciting products. We are building a team that is continuously improving ' +
            //   'front-end systems which help make our clients’ lives easier.',
            responsibility: {
              // tslint:disable-next-line:max-line-length
              1: 'Translate concepts into user flows, wireframes, mockups and prototypes that lead to intuitive user experiences.',
              // tslint:disable-next-line:max-line-length
              2: 'Facilitate the client’s product vision by researching, conceiving, sketching, prototyping and user-testing experiences for digital products.',
              // tslint:disable-next-line:max-line-length
              3: 'Design and deliver wireframes, user stories, user journeys, and mockups optimized for a wide range of devices and interfaces.',
              4: 'Identify design problems and devise elegant solutions.',
              // tslint:disable-next-line:max-line-length
              5: 'Make strategic design and user-experience decisions related to core, and new, functions and features.',
              6: 'Take a user-centered design approach and rapidly test and iterate your designs.',
              7: 'Collaborate with other team members and stakeholders.',
              8: 'Ask smart questions, take risks and champion new ideas.',
            },
            requirement: {
              1: 'Proficient with Swift and Cocoa Touch',
              2: 'Experience with iOS frameworks such as Core Data, Core Animation, etc.',
              3: 'Experience with Android framework.',
              4: 'Experience with Kotlin is a preferred.',
              5: 'Familiarity with RESTful APIs to connect iOS and Android applications to back-end services',
              6: 'Experience with working with back-end framework such as Laravel, dJango or Flask',
              7: 'Knowledge of other web technologies and UI/UX standards',
              8: 'Understanding of design principles and interface guidelines',
              9: 'Familiarity with cloud message APIs and push notifications',
              10: 'Proficient understanding of code versioning using Git',
            },
            benefit: {
              1: 'Social Security Insurance + Health Insurance',
              2: 'Transportation fee when traveling',
              // 3: "Phone call fee with sim cards and a phone",
              // 4: "Commission",
              5: 'Free lunch',
            },
            whatYouGet: {
              1: 'Challenging work experience with opportunities to work with top-tier corporate clients',
              2: 'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
              3: 'Classes and training program at Thailand\'s top accelerator program',
            },
          },
          ux: {
            name: 'UX/UI Designer',
            // description:
            //   '25-35k',
            responsibility: {
              // tslint:disable-next-line:max-line-length
              1: 'Design both UX and UI for both mobile and website. We understand the differences of UX and UI but as a start-up company we need to move fast. To be both a great visual artist with user experience understanding will benefit our time meticulously.',
              // tslint:disable-next-line:max-line-length
              2: 'User research and analysis for UX improvement',
              // tslint:disable-next-line:max-line-length
              3: 'Loves talking to people, conducting user interview, gaining insights and able to set up objectives of the interview.',
              4: 'Collaborate with developers, stakeholders and CEO to create great design',
            },
            requirement: {
              1: 'Previous experience working with developers team and UX UI responsive design understanding',
              2: 'Fast learner and mover',
              3: 'Flexible and hardworking',
              4: 'Sketch or Abode XD User. We do not look for UX/UI that rely only on Photoshop and Illustrator',
              5: 'At least 2 years of experience',
            },
            benefit: {
              1: 'Social Security Insurance + Health Insurance',
              2: 'Transportation fee when traveling',
              // 3: "Phone call fee with sim cards and a phone",
              // 4: "Commission",
              5: 'Free lunch',
            },
            whatYouGet: {
              1: 'Challenging work experience with opportunities to work with top-tier corporate clients',
              2: 'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
              3: 'Classes and training program at Thailand\'s top accelerator program',
            },
          },
        },
        applyJob: 'สมัครตำแหน่งนี้',
      },
      contactEmail: {
        title: 'หากไม่เจอตำแหน่งที่เหมาะกับคุณ ลองติดต่อเราสิ',
        text: 'ส่งประวัติของคุณเข้ามา แล้วเราจะติดต่อกลับไปโดยเร็วที่สุด',
      },
      getStarted: {
        title: 'คุณพร้อมที่จะเปลี่ยนโลกไปกับเราหรือยัง?',
        signIn: 'เข้าสู่ระบบ',
        register: 'สมัครเลย',
      },
    },
  },
};
