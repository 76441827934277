import axios from 'axios';

export default () => new Promise((resolve, reject) => {
  axios.get(`${process.env.VUE_APP_API_LOCATION}/settings/announcement`).then(({ data }) => {
    let announcement = {};
    if (data) {
      announcement = data;
    }
    resolve(announcement);
  }).catch((error) => {
    reject(error);
  });
});
