import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import VueYouTubeEmbed from 'vue-youtube-embed';
import Meta from 'vue-meta';
const VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);
Vue.use(Meta);
Vue.use(VueYouTubeEmbed);

// if you want to install the component globally with a different name
Vue.use(VueYouTubeEmbed, { global: true });

Vue.config.productionTip = false;

Vue.use(VueI18n);

// set locales
import locales from './locales';

const lang = window.localStorage.getItem('language') || 'th';

const i18n = new VueI18n({
  locale: lang,
  messages: locales,
});

(window as any).Vue = new Vue({
  // metaInfo: {
  //   // link: [{ rel: 'favicon', href: 'favicon.ico' }],
  //   meta: [
  //     { httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8' },
  //     { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //   ],
  // },
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

// For prerender SPA plugin
document.addEventListener('DOMContentLoaded', () => {
  (window as any).Vue.$mount('#app');
});

store.dispatch('lang/change', lang);
