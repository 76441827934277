// config here and config in route
const career: any = {
  headOfTechnology: {
    name: 'Head of Technology<br> (3-7+ years)',
    subName:
      'Head of Technology (3-7+ years)',
    // tslint:disable-next-line:max-line-length
    youHaveShouldBe: [
      'Required skills / experiences3 - 5 + years mobile application / web application developments3 + years experience in node.js, React Native, php, sql, no - sq',
    ],
    responsibility: [
      'Design and develop web/mobile application (frontend architecture, backend architecture, focus on performance reliability security maintainable system)',
      'Manage team of 4-6 developers',
      'Take the initiative in thought leadership, innovation and creativity of technology team. Build proper culture and working ethic within the team',
      'Design and develop frontend architecture, backend architecture, focus on performance reliability security maintainable system.',
      'Represent the technological agenda in staff meetings and when making hiring decisions',
      'Maintain current knowledge of technology landscape and developments',
      'Consolidate our technology platforms and create plans for each',
      'Track, analyze and monitor technology performance metrics',
      'Identify new areas of digital marketing opportunities and create plans to implement them for us and our clients',
    ],
    interestedSkills: [
      'Project management and organization skills',
      'Typescript.',
      'Jest.',
      'React, Vue. React-Native, Cordova.',
      'Redux, MobX.',
      'GraphQL (apollo, relay).',
      'Functional Programming, RxJS.',
      'Java, Obj C, Swift, Kotlin.',
      'Node.js, PHP, dotNet core or Go.',
      'Load balance, Queue, Caching.',
      'DevOps (AWS, GCP).',
      'Voip (voice, video, telephony).+ IVR',
      'Machine Learning/ Artificial Intelligence',
      'Big Data',
    ],
    benefits: [
      'Social Security Insurance + Health Insurance',
      'Free lunch when working at the office',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  accountExecutive: {
    name: 'Account Executive',
    subName: 'Account Executive',
    // tslint:disable-next-line:max-line-length
    youHaveShouldBe: [
      'At least 2 - year experience as sales representative or related field',
      'Good attitude in workplace and maintaining relationship with others',
      'Open minded and strategic thinking ability',
      'Good command of English',
      'B2B experience will have high consideration',
    ],
    responsibility: [
      'Identify and develop sales opportunities',
      'Initiate business plans and pitching projects',
      'Efficiently manage and prioritize various sales-related activities',
      'Maintain favorable customer relations by demonstrating problem-solving skills',
      'Contribute directly to the success and growth of the company',
    ],
    benefits: [
      'Social Security Insurance + Health Insurance',
      'Transportation fee when traveling',
      'Phone call fee with sim cards and a phone',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  assistant: {
    name: 'Team Assistant',
    subName: 'Team Assistant',
    youHaveShouldBe: [
      'New graduates are welcome',
      'Good attitude in workplace and maintain relationship with others',
      'Open minded and strategic thinking ability',
      'Good command of English',
      'Be responsible, creative, high E.Q. and assertive',
      'Have a pleasant personality, leadership skill, and communication skill',
      'Ability to work under pressure',
    ],
    responsibility: [
      'Internal and external coordination',
      'Conduct projects and documentation as assigned',
      'Report directly to the CEO',
      'Manage team schedules and appointments',
      'Conduct out-of-office activities and support the preparation process.',
      'Manage a reimburse the expenses system and keep records',
      'Support team according to the task assigned',
      'Have an Interest and an ability to utilize digital technology in the workplace',
      'Have an interest in technology and keen to try innovative tech before others',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Transportation fee when traveling',
      'Phone call fee with sim cards and a phone',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Working in Tech environment',
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Cooperate with government sectors to raise awareness in mental health issue for Thai people in each segment',
      'We are currently developing projects in both academic and international sectors.',
    ],
  },
  mobiledev: {
    name: 'Mobile developer <br>(Native iOS, Android)',
    subName: 'Mobile developer <br>(Native iOS, Android)',
    youHaveShouldBe: [
      'Proficient with Swift and Cocoa Touch',
      'Experience with iOS frameworks such as Core Data, Core Animation, etc.',
      'Experience with Android framework.',
      'Experience with Kotlin is a preferred.',
      'Familiarity with RESTful APIs to connect iOS and Android applications to back-end services',
      'Experience with working with back-end framework such as Laravel, dJango or Flask',
      'Knowledge of other web technologies and UI/UX standards',
      'Understanding of design principles and interface guidelines',
      'Familiarity with cloud message APIs and push notifications',
      'Proficient understanding of code versioning using Git',
    ],
    responsibility: [
      // tslint:disable-next-line:max-line-length
      'Translate concepts into user flows, wireframes, mockups and prototypes that lead to intuitive user experiences.',
      // tslint:disable-next-line:max-line-length
      'Facilitate the client’s product vision by researching, conceiving, sketching, prototyping and user-testing experiences for digital products.',
      // tslint:disable-next-line:max-line-length
      'Design and deliver wireframes, user stories, user journeys, and mockups optimized for a wide range of devices and interfaces.',
      'Identify design problems and devise elegant solutions.',
      // tslint:disable-next-line:max-line-length
      'Make strategic design and user-experience decisions related to core, and new, functions and features.',
      'Take a user-centered design approach and rapidly test and iterate your designs.',
      'Collaborate with other team members and stakeholders.',
      'Ask smart questions, take risks and champion new ideas.',
    ],
    benefits: [
      'Social Security Insurance + Health Insurance',
      'Transportation fee when traveling',
      'Free lunch',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  ux: {
    name: 'UX/UI Designer',
    subName: 'UX/UI Designer',
    youHaveShouldBe: [
      'Previous experience working with developers team and UX UI responsive design understanding',
      'Fast learner and mover',
      'Flexible and hardworking',
      'Sketch or Abode XD User. We do not look for UX/UI that rely only on Photoshop and Illustrator',
      'At least 2 years of experience',
    ],
    responsibility: [
      // tslint:disable-next-line:max-line-length
      'Design both UX and UI for both mobile and website. We understand the differences of UX and UI but as a start-up company we need to move fast. To be both a great visual artist with user experience understanding will benefit our time meticulously.',
      // tslint:disable-next-line:max-line-length
      'User research and analysis for UX improvement',
      // tslint:disable-next-line:max-line-length
      'Loves talking to people, conducting user interview, gaining insights and able to set up objectives of the interview.',
      'Collaborate with developers, stakeholders and CEO to create great design',
    ],
    benefits: [
      'Social Security Insurance + Health Insurance',
      'Transportation fee when traveling',
      'Free lunch',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  businessDevelopment: {
    name: 'Business Development/Business Analyst',
    subName: 'Business Development/Business Analyst',
    youHaveShouldBe: [
      'At least 1 year experience as a Business development/ Business analyst or related role',
      'Strategic thinking, result oriented and link initiatives/actions to company objectives',
      'Having commercial and economic awareness',
      'Excellent in communication and interpersonal skills',
      'Good attitude in workplace and maintaining relationship with others',
      'Good command of English',
    ],
    responsibility: [
      'Study business requirements, analyze data and translate results',
      'Conduct root cause analysis or correlation studies when required',
      'Consolidate standardized data for reporting purposes',
      'Monitor, alert, and communicate appropriate trends based on business goals',
      'Establish project management skills with project scheduling and tracking documents',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  psychologist: {
    name: 'Full-time Psychologist',
    subName: 'Full-time Psychologist',
    youHaveShouldBe: [
      'วุฒิ ปริญญาโทหรือเอก สาขาวิชาจิตวิทยาการให้คำปรึกษา หรือสาขาอื่นๆ ที่เกี่ยวข้องกับการให้คำปรึกษาทางด้านจิตวิทยา',
      'มีประสบการณ์ทำงานในฐานะผู้ให้คำปรึกษาทางด้านจิตวิทยามาแล้วมากกว่า 2 ปี',
    ],
    responsibility: [
      'งานให้คำปรึกษาก็มีให้ทำเก็บเกี่ยวประสบการณ์ตลอด แต่ส่วนมากเราเน้นคนไข้เบาๆไม่ใช่เคสหนัก เพราะเราสนใจทางด้าน wellness มากกว่า',
      'มีความรับผิดชอบ มีความคิดสร้างสรรค์ และมี E.Q.ดี กล้าแสดงออก สามารถสอนสัมมนา หรือ พูดประชาสัมพันธ์โครงการการให้คำปรึกษาได้ มีบุคลิกภาพ ภาวะความเป็นผู้นำ มีมนุษยสัมพันธ์ และทักษะการติดต่อสื่อสารที่ดี',
      'งานนี้เจอคนเยอะ ต้องได้เจอกับคนมากมาย ไม่ว่าจะออนไลน์หรือ ออฟไลน์',
      'สามารถเดินทางไปต่างจังหวัดเพื่อจัดกิจกรรมได้',
      'มีงานบ้างส่วนต้องไปจัดกิจกรรมนอกสถานที่ด้วย สายเที่ยวชอบลุยต้องมา!',
      'สนใจและมีความสามารถในการใช้เทคโนโลยีในปัจจุบันในการทำงาน',
      'ชอบเทคโนโลยี อยากทำอะไรใหม่ๆที่สร้างสรรค์ ที่นี่มีให้คุณได้ลองก่อนเพื่อน',
    ],
    benefits: [
      'ประกันสังคม ประกันสุขภาพกลุ่ม',
      'ค่าเดินทางเกี่ยวข้องกับเดินทางไปข้างนอก',
      'ฟรีอาหารกลางวัน',
    ],
    whatYouGet: [
      'ทำงานกับเทคโนโลยีตลอด',
      'ลูกค้าของเราเป็นบริษัทชั้นนำของประเทศไทย',
      'เราร่วมมือพัฒนาสังคมกับมูลนิธิ และสถาบันการศึกษา เพื่อสร้างเครื่องมือที่จะช่วยเหลือคนไทยได้อย่างยั่งยืน',
      'ทางภาครัฐเราก็ไม่ละทิ้ง กำลังจะมีโปรเจคร่วมกับภาครัฐเพื่อผลักดันเรื่องสุขภาพจิตสำหรับคนไทยในหลายๆมุม',
      'เรากำลังพัฒนาโปรเจคหลายๆด้านทั้ง ภาคการศึกษา และต่างประเทศ',
    ],
  },
  socialImpactManager: {
    name: 'Social Impact Manager',
    subName: 'Social Impact Manager',
    description: '&nbsp;&nbsp;&nbsp;Ooca is setting up a CSR project that focuses on improving access to mental health nationwide. In 2019, we are focusing on improving access and provide free counseling service to university students in order to support their mental wellness and prevent suicide. We are looking for a well-rounded managerial and collaborative person. The CSR project manager will work directly with C-level executives and will be responsible for managing and running a CSR project, connecting and working with education institutes, construct and run promotion plan, connect and fundraised through corporates grants and crowdfunding. The ideal candidate is a senior well-managed, highly self-motivated, professional, and capable of managing workload and prioritizing tasks in a fast-paced corporate environment. This is an excellent opportunity to join a growing company with competitive benefits.',
    youHaveShouldBe: [
      'Successful profile of fundraising',
      'Previous managerial background with operational experiences',
      'Understand and share vision toward social oriented projects',
      'Quick learner, open-minded, tech-savvy, and like to learn new things.',
      'Basic use of Microsoft office (Word, Powerpoint, Excel and etc.).',
      'Familiar with social enterprise or NGO and understand working morale within this area',
      'Fast and attention to details',
      'Ability to work under pressure',
      'Positive attitude with team spirit',
      'Liking to work in startup environment',
    ],
    responsibility: [
      'Raise funds through corporates, charity events and crowdfunding platform targeting 25 Million THB within 12 months',
      'Act as a key representative to the CSR project, working, connecting and maintaining relationships with beneficiaries and patroned institutes',
      'Manage and runs the CSR project',
      'Draft proposals and PR pieces',
      'Plan operational task and promotion plan',
      'Work as goal-oriented and directly reports to the CEO',
    ],
    benefits: [
      'Social Security Insurance + Health Insurance + Group insurance',
      'Transportation fee when traveling',
      'Free lunch(3 days/week)',
      'Workshop/Course',
      'Monthly party',
      'Flexible work hour team commitment',
      'Use OOCA free',
      'Employee family 25% discount up to 2 account',
      'Self Development allowance',
      'Dental treatment reimbursement',
      'Scholarship',
      '5 days work',
      'Life insurance',
      'Bonus (upon performance)',
      'Travel allowance',
      'Be able to work from home',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at CommonGround, Rama 9',
      'Classes and training program at Thailand’s top accelerator program',
      'Work for a profitable business with the atmosphere of a fun start-up.',
      'Work on an interesting problem which will challenge and improve your skill',
    ],
  },
  graphicDesigner: {
    name: 'Graphic Designer',
    subName: 'Graphic Designer',
    youHaveShouldBe: [
      'Design visual requirement for Ooca product such as; illustration, print-materials and etc.',
      'Collaborate with developers, stakeholders and UX/UI designer to create great design',
    ],
    responsibility: [
      'Great sense of esthetic design and urge to understand before design',
      'Fast learner and mover',
      'Flexible and hardworking',
      'Sketch, Adobe Photoshop, Adobe illustrator or etc.',
      'Commitment with deadlines',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Transportation fee when traveling',
      'Phone call fee with sim cards and a phone',
      'Commission',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  marketingCommunication: {
    name: 'Marketing Communication',
    subName: 'Marketing Communication',
    youHaveShouldBe: [
      'At least 2 - year experience as Marketing Communication, Content, PR, Branding or relevant field',
      'Excellent communication skills both orally and in writing',
      'Excellent interpersonal skills',
      'Good Presentation skills',
      'Familiarity with B2B and B2C marketing activities',
      'Knowledge of SEO and Google Analytics',
      'Creativity and an ability to produce innovative and original ideas',
      'Expertise in social media platforms',
    ],
    responsibility: [
      'Set up plan promote our company, products and services through various channels',
      'Coordinate the design of materials and distribute in online and offline channels',
      'Produce marketing copy for our website and other channels',
      'Network with industry experts and potential clients to drive brand awareness',
      'Gather customer feedback to inform sales and product teams including analysing the success of marketing activities and creating report',
      'Design content marketing strategies and set short-term goals',
      'Undertake content marketing initiatives to achieve business targets',
      'Collaborate with design other related teams to produce high quality content',
      'Deliver engaging content on a regular basis and inspire team members',
      'Edit, proofread and improve content',
      'Optimize content considering SEO and Google Analytics and analyze web traffic metrics',
      'Share content through various channels, ensuring strong web presence',
      'Receive customer feedback and generate ideas to increase customer engagement',
      'Carrying out market research in order to keep up to date with customer trends, as well as trying to predict future trends',
      'Planning publicity strategies and activities',
      'Writing and producing presentations and other releases',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Transportation fee when traveling',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  reactNativeDeveloper: {
    name: 'React Native Developer',
    subName:
      'React Native Developer',
    youHaveShouldBe: [
      'Experience with React Native APIs, ReactJS, Javascript, ECMAScript and JSX',
      'Experience with iOS or Android development is a plus',
      'Experience with Web development technology stack, HTML/JS/CSS and other web development libraries and frameworks is plus',
      'Experience in design, development and deployment of mobile apps',
      'Knowledge of Restful Web-Services, JSON, HTTP, RPC, GraphQL networking protocols',
      'Experience in webRTC and video streaming is plus',
      'Experience with common front-end development tools such as Babel, Webpack, Yarn, NPM, etc.',
      'New Grad also welcome!!'
    ],
    responsibility: [
      'Build pixel-perfect, buttery smooth UIs across both mobile platforms.',
      'Leverage native APIs for deep integrations with both platforms.',
      'Diagnose and fix bugs and performance bottlenecks for performance that feels native.',
      'Reach out to the open source community to encourage and help implement mission-critical software fixes—React Native moves fast and often breaks things.',
      'Maintain code and write automated tests to ensure the product is of the highest quality.',
      'Transition existing React web apps to React Native.',
    ],
    interestedSkills: [
      'Jest.',
      'React, Vue. React-Native',
      'Redux, MobX.',
      'GraphQL (apollo, relay).',
      'Node.js, Go.',
      'Load balance, Queue, Caching.',
      'DevOps (AWS, GCP).',
      'WebRTC'
    ],
    benefits: [
      'Flexible working hours',
      'Free snacks and soft drinks',
      'Group health insurance (OPD/IPD/Dental)',
      'Laptop allowance',
      'Welfare according to Thai Labour Protection Act.',
      'Internal Seminar',
      'Monthly party',
      'Outing annually'
    ],
  },
  seniorFullStackDeveloper: {
    name: 'Senior Full Stack Developer',
    subName:
      'Senior Full Stack Developer',
    youHaveShouldBe: [
      'Strong in client side frameworks such as React, Vue etc.',
      'Excellent HTML/CSS skills – understand not only how to build the data, but how to make it look appealing.',
      'Experience developing backend languages/frameworks such as NodeJS, Go or Laravel.',
      'Other technologies might include: Google Cloud Platform, Firebase, Kubernetes, Docker.',
      'Love new technologies, approach and want to use the best tools available. We want people who can help us continually evolve our stack.',
      'Experience on Devops CICD development methodology.',
      'Sociable, friendly and willing to talk and argue about the problem to find the solution, while willing to take responsibility and ownership of the product.',
      'Understand that making mistakes is good if you’re learning. When we fail, we fail fast and learn from our mistakes.',
      'Experience leading teams and mentoring other developers, with comfort in Agile.',
    ],
    responsibility: [
      'Design and develop highly scalable, highly available, reliable, secure and fault tolerant systems with minimal guidance.',
      'Ensure that these components and the overall application are robust and easy to maintain.',
      'Translate business requirements into scalable and extensible design.',
      'Maintain code and write automated tests to ensure the product is of the highest quality.',
      'Proactively address performance issues and bugs.',
      'Identify code design problems and devise elegant solutions.',
      'Write technical documents and guideline documents.',
    ],
    interestedSkills: [
      'Jest.',
      'React, Vue. React-Native',
      'Redux, MobX.',
      'GraphQL (apollo, relay).',
      'Node.js, Go.',
      'Load balance, Queue, Caching.',
      'DevOps (AWS, GCP).',
      'WebRTC'
    ],
    benefits: [
      'Flexible working hours',
      'Free snacks and soft drinks',
      'Group health insurance (OPD/IPD/Dental)',
      'Laptop allowance',
      'Welfare according to Thai Labour Protection Act.',
      'Internal Seminar',
      'Monthly party',
      'Outing annually',
    ],
  },
  digitalMediaBuyer: {
    name: 'Digital Media Buyer',
    subName:
      'Digital Media Buyer',
    youHaveShouldBe: [
      'Bachelor\'s Degree in Marketing, Mass Communication, Communication Arts or any related fields',
      'Proven work experience in buying digital media advertising channel directly such as Facebook ads, Instagram ads, Line@, Twitter, Google Adwords or others',
      'Business acumen, consumer insight with strong analytical skills, communication skills (English & Thai), negotiation skills, interpersonal skills, problem-solving skills, and detail-oriented',
      'Ability to work under dynamic environment and manage aggressive timelines',
      'Good communication skills, good attitude, and good human relations',
      'Good command of English (conversational level)',
    ],
    responsibility: [
      'Implement online strategic plan and coordinate with internal closely to align with core marketing strategy for ooca online business.',
      'Coordinate with other related agencies and responsible for other tasks as assigned.',
      'To set up and optimize all paid campaigns on Adwords, Facebook Ads, Twitter Ads and another platform to achieve target.',
      'Analyzing the analytic reports to identify the campaign effectiveness on a monthly basis or as a project to ensure the KPIs achievement.',
      'Tracking of marketing expenditure and ensuring it is kept within budget.',
      'Prepare monthly advertising measurement reports, if assigned.',
    ],
    benefits: [
      'Flexible working hours',
      'Free snacks and soft drinks',
      'Group health insurance (OPD/IPD/Dental)',
      'Laptop allowance',
      'Welfare according to Thai Labour Protection Act.',
      'Internal Seminar',
      'Monthly party',
      'Outing annually'
    ],
  },
  teleSalesRepresentative: {
    name: 'Telesales Representative Job Description Template',
    subName:
      'Telesales Representative Job Description Template',
    description:
    'We are looking for a well-spoken Telesales Representative to be responsible for soliciting sales and donations over the telephone. The Telesales Representative\'s responsibilities include contacting potential customers, reading from a prescribed script, focusing on customer needs to close sales, and documenting customer information, purchases, and reactions.A successful Telesales Representative has excellent communication skills as well as sales ability. You should be meticulous in keeping records, and able to handle all aspects of making a sale.',
    youHaveShouldBe: [
      'Excellent communication skills.',
      'Creative thinking skills.',
      'The patience and ability to engage customers in conversation.',
      'Good sales ability.',
      'Working knowledge of relevant computer software.',
      'Excellent interpersonal and problem solving skills.',
      'The ability to handle rejection and stress in soliciting customers.',
    ],
    responsibility: [
      'Initiating sales with potential customers over the phone.',
      'Asking questions to engage customers and keep the conversation going.',
      'Listening to the customers\' needs to generate repeated sales.',
      'Gathering and documenting customer information, payment methods, purchases, and reactions to products.',
      'Keeping up to date on all products and informing customers of new products.',
      'Answering customers\' questions on the products.',
      'Meeting sales quotas.',
    ],
    benefits: [
      'Flexible working hours',
      'Free snacks and soft drinks',
      'Group health insurance (OPD/IPD/Dental)',
      'Laptop allowance',
      'Welfare according to Thai Labour Protection Act.',
      'Internal Seminar',
      'Monthly party',
      'Outing annually'
    ],
  },
  teamAssist: {
    name: 'TEAM ASSISTANT',
    subName:
      'TEAM ASSISTANT',
    description:
    'Our startup team is looking for a proactive, reliable and task-oriented assistant. The team assistant will work directly with C-level executives and will be responsible for performing a number of administrative duties. The ideal candidate is highly self-motivated, professional, and capable of managing workload and prioritizing tasks in a fast-paced corporate environment. This is an excellent opportunity to join a growing company with competitive benefits.',
    youHaveShouldBe: [
      'Quick Learner, open-minded, tech-savvy, and like to learn new things',
      'Basic use of Microsoft office (Word, Powerpoint, Excel, etc.)',
      'Service-minded and polite',
      'Fast and attention to details',
      'Adventurous and patient',
      'Ability to work under pressure',
      'Positive attitude with team spirit',
      'Like to work in a startup environment',
    ],
    responsibility: [
      'Act as a key member by coordinating with company internal and external.',
      'Produce accurate documents and develop and maintain the filing system.',
      'Support and facilitate the team as assigned.',
      'Work as goal-oriented and directly reports to the CEO.',
      'Help preparing materials whenever working off-site.',
      'Manage CEO and team calendar and some other schedule arrangements.',
      'Manage team reimbursement  by keeping track of expenses and purchase receipts, etc.',
      'Check frequently the levels of office supplies and place appropriate orders.',
      'Provide other administrative support as needed.',
    ],
    benefits: [
      'Flexible working hours',
      'Free snacks and soft drinks',
      'Group health insurance (OPD/IPD/Dental)',
      'Laptop allowance',
      'Welfare according to Thai Labour Protection Act.',
      'Internal Seminar',
      'Monthly party',
      'Outing annually'
    ],
  },
  customerService: {
    name: 'Customer Service Representative',
    subName:
      'Customer Service Representative',
    description:
    'We are looking for a customer service representative who will act as a liaison, provide product/services information and resolve any emerging problems that our customer accounts might face with accuracy and efficiency.',
    youHaveShouldBe: [
      'Customer orientation and ability to adapt/respond to different types of characters',
      'Strong phone contact handling skills and active listening as well as other channels e.g. facebook, app-chat and etc.',
      'Excellent communication and presentation skills',
      'Ability to multitask, prioritize, and manage time effectively',
      'Patient, empathetic, and passionately communicative and love to talk!',
      'Problem-solving skills',
      'Open-minded and willing to learn',
      'Tech savvy',
      'Liking to work in startup environment',
    ],
    responsibility: [
      'Manage and answer amounts of incoming calls, emails, app-chat, web-chat,  facebook and etc. both from corporate clients and individual customers.',
      'Identify and assess customers’ needs to achieve satisfaction.',
      'Build sustainable relationships and trust with customer accounts through open and interactive communication, also coordinating with the team to acknowledge the issues and come up with best solutions.',
      'Provide accurate, valid and complete information by using the right methods/tools.',
      'Handle customer complaints, provide appropriate solutions and alternatives within the time limits; follow up to ensure resolution.',
      'Keep records of customer interactions, process customer accounts and file documents.',
      'Follow communication procedures, guidelines and policies.',
    ],
    benefits: [
      'Flexible working hours',
      'Free snacks and soft drinks',
      'Group health insurance (OPD/IPD/Dental)',
      'Laptop allowance',
      'Welfare according to Thai Labour Protection Act.',
      'Internal Seminar',
      'Monthly party',
      'Outing annually'
    ],
  },
  providerRelationsManager: {
    name: 'Provider Relations Manager',
    subName:
      'Provider Relations Manager',
    youHaveShouldBe: [
      'Proven experience in health-care field especially in mental health',
      'Good attitude in workplace and maintaining relationship with others',
      'Strong interpersonal and communication skills with good service mind',
      'Experience as drug detailing will have high consideration',
      'Team management abilities',
      'Tech savvy',
    ],
    responsibility: [
      'Acquire/Recruit/Interview new psychiatrist/psychologist to join OOCA online counseling platform.',
      'Maintain good relationship and communicate with providers on OOCA platform',
      'Onboard psychiatrist/psychologist on OOCA platform.',
      'Community building within groups on mental health providers on OOCA',
      'Set up provider shifts and arrange to have shifts covered',
      'Oversee the daily operations of providers in OOCA',
      'Delegate tasks to providers and supervising their performance',
      'Ensure satisfaction and gracefully handle any complaints',
      'Help providers resolve on-the-job challenges',
      'Report issues and training needs',
    ],
    benefits: [
      'Flexible working hours',
      'Free snacks and soft drinks',
      'Group health insurance (OPD/IPD/Dental)',
      'Laptop allowance',
      'Welfare according to Thai Labour Protection Act.',
      'Internal Seminar',
      'Monthly party',
      'Outing annually'
    ],
  },
  operationsManager: {
    name: 'Operations Manager',
    subName:
      'Operations Manager',
    description:
      'OOCA is seeking to hire an Operations Manager to join our management team. Manage overall operations and is responsible for the effective and successful management of labor, productivity, quality control and safety measures as established and set for the Operations Department. Ensure safe and efficient operations. Serve as a company representative on regulatory issues. Enhance the operational procedure, systems and principles in the areas of information flow and management, business processes, enhanced management reporting and looks for opportunities to expand systems. Carry out supervisory responsibilities in accordance with company\'s policies and applicable laws. You will also be assisting our Human Resources with recruiting, when necessary, and help promote a company culture that encourages morale and performance. Successful candidates will have a Bachelor\'s degree in operations management (or a related field) and have prior experience in a management or leadership position.',
    youHaveShouldBe: [
      'Bachelor’s degree in operations management or related field.',
      'Experience in management, operations, and leadership.',
      'Ability to build consensus and relationships among managers, partners, and employees.',
      'Excellent communication skills.',
    ],
    responsibility: [
      'Provide inspired leadership for the organization.',
      'Make important policy, planning, and strategy decisions.',
      'Develop, implement and review operational policies and procedures.',
      'Assist HR with recruiting when necessary.',
      'Help promote a company culture that encourages top performance and high morale.',
      'Oversee budgeting, reporting, planning, and auditing.',
      'Work with senior stakeholders.',
      'Ensure all legal and regulatory documents are filed and monitor compliance with laws and regulations.',
      'Identify and address problems and opportunities for the company.',
      'Support worker communication with the management team.',
    ],
    benefits: [
      'Flexible working hours',
      'Free snacks and soft drinks',
      'Group health insurance (OPD/IPD/Dental)',
      'Laptop allowance',
      'Welfare according to Thai Labour Protection Act.',
      'Internal Seminar',
      'Monthly party',
      'Outing annually'
    ],
  },
  hrAdministrator: {
    name: 'HR Administrator',
    subName:
      'HR Administrator',
    youHaveShouldBe: [
      'Degree in Business Management or related field',
      'Min 2 years of relevant administration experience',
      'Pleasant with good coordination and interpersonal skills',
      'Good command of written and spoken English',
      'Excellent computer literacy in Microsoft Office and related software',
      'Able to multitask and pay attention to details',
      'Able to learn fast & follow instructions',
      'Must be able to write official document',
    ],
    responsibility: [
      'Provide full administrative and office support activities to operations and HR',
      'Responsible for the upkeep and maintenance of office facilities and equipment',
      'Provide support for local purchase and coordinate the purchase of multiple division’s standardized supplies, materials, and equipment; maintain an accurate list; receive, check and stock supplies',
      'Receive and log purchase requisitions for office supplies',
      'Communicate with shipping agents for all shipments and process Customs clearance',
      'Review shipping invoices for accuracy; prepare monthly expenditure reports to the Operations Controller',
      'Assist HR for update and maintain HR-related information e.g. training record, leave application, keep control for general documentations forms to comply with ISO system',
      'To assist the Operations Controller with other operational duties as and when assigned',
    ],
    benefits: [
      'Flexible working hours',
      'Free snacks and soft drinks',
      'Group health insurance (OPD/IPD/Dental)',
      'Laptop allowance',
      'Welfare according to Thai Labour Protection Act.',
      'Internal Seminar',
      'Monthly party',
      'Outing annually'
    ],
  },
};

export default career;
