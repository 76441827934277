export default {
  en: {
    counseling: {
      hero: {
        title: 'See a doctor now',
        subTitle: 'You can contact a doctor from your home, or on the go. ',
        callToAction: 'try it out',
      },
      weHelp : {
        title : 'what can we help',
        subTitle : 'At Ooca, we have providers expertised in various areas',
        typeHelp : {
          couple : 'couple/maritual',
          child : 'child psychology',
          depression : 'depression',
          family : 'family',
        },
      },
      personalized : {
        title : 'personalized counselling',
        subTitle : 'Select up to 3 topics that best fit your concern ' +
        'and select one of your best matched provider.',
        topics : {
          stress : 'Stress',
          relationship : 'Relationship',
          parenting : 'Parenting',
          career: 'Career',
          couple: 'Couple',
          insomnia: 'Insomnia',
          learning: 'Learning',
          depression: 'Depression',
          school: 'School refusal',
          mood: 'Mood swing',
          sex: 'Sex',
          concentration: 'Concentration',
        },
        provider : {
          type : {
            psychologist : 'psychologist',
            psychiatrist : 'psychiatrist',
          },
        },
        certNo : 'Certificate No.',
        exprtise : 'Exprtise topics',
        makeAppointment : 'Make appointment',
      },
      connected : {
        title : 'Get connected, anywhere, anytime',
        subTitle : 'Just like an in-person visit, the doctor takes your' +
        ' history and symptoms, performs an exam ' +
        'and may recommend treatment.',
      },
      matching : {
        title : 'matching with expert',
        subTitle : 'Board-certified providers',
      },
      step : {
        title : 'ooca is easy to use, just few steps to get started',
      },
      getStarted : {
        title : 'ready to get started?',
        subTitle : 'let ooca take care of you or the one you loved',
        getStarted : 'get started',
        sendAGift : 'send a gift',
      },
    },
  },
  th: {
    counseling: {
      hero: {
        title: 'ปรึกษาได้ทุกที่ ทุกเวลา',
        subTitle: 'คุณสามารถพูดคุยกับผู้ให้คำปรึกษาได้อย่างสบายใจจากที่บ้าน หรือที่ไหนก็ได้ตามสะดวก ',
        callToAction: 'เริ่มเลย',
      },
      weHelp : {
        title : 'คุยอะไรกับเราได้บ้าง',
        subTitle : 'อูก้ามีผู้ให้คำปรึกษาที่เชี่ยวชาญในหลายด้าน ',
        typeHelp : {
          couple : 'ชีวิตคู่/การแต่งงาน',
          child : 'ปัญหาในการเลี้ยงลูก',
          depression : 'ภาวะซึมเศร้า',
          family : 'ปัญหาภายในครอบครัว',
        },
      },
      personalized : {
        title : 'ปรึกษาได้ ตรงใจคุณ',
        subTitle : 'คุณสามารถเลือกหัวข้อที่ต้องการปรึกษาได้ถึง 3 หัวข้อ ' +
        'เพื่อให้ระบบได้แนะนำผู้เชี่ยวชาญที่ตรงกับกับความต้องการของคุณ',
        topics : {
          stress : 'ปัญหาความเครียด',
          relationship : 'ปัญหาความสัมพันธ์',
          parenting : 'การเลี้ยงลูก',
          career: 'ปัญหาในการทำงาน',
          couple: 'ปัญหาความรัก',
          insomnia: 'นอนไม่หลับ',
          learning: 'การเรียนรู้',
          depression: 'ซึมเศร้า',
          school: 'การไปโรงเรียน',
          mood: 'อารมณ์แปรปรวน',
          sex: 'ปัญหาเพศสัมพันธ์',
          concentration: 'สมาธิสั้น',

        },
        provider : {
          type : {
            psychologist : 'นักจิตวิทยา',
            psychiatrist : 'จิตแพทย์',
          },
        },
        certNo : 'หมายเลขใบอนุญาต',
        exprtise : 'หัวข้อที่เชี่ยวชาญ',
        makeAppointment : 'ทำการนัดหมาย',
      },
      connected : {
        title: 'ง่ายกว่า และไม่ต้องเดินทาง',
        subTitle: 'ผู้ให้คำปรึกษาจะชวนให้ท่านค้นหาคำตอบในใจของคุณไปพร้อมๆกัน และร่วมกันหาทางออกที่เหมาะสม',
      },
      matching : {
        title : 'จับคู่กับหมอที่ตรงกับความต้องการ',
        subTitle : 'ผู้ให้คำปรึกษาของเราทุกคนผ่านการตรวจสอบประวัติและรับรองการทำงาน',
      },
      step : {
        title : 'ปรึกษาด้วยอูก้า ใช้งานง่าย เริ่มได้เลยในไม่กี่ขั้นตอน',
      },
      getStarted : {
        title : 'พร้อมเริ่มใช้งานอูก้าหรือยัง?',
        subTitle : 'ให้อูก้าช่วยดูแลคุณหรือคนที่คุณรัก',
        getStarted : 'เริ่มใช้งานเลย',
        sendAGift : 'บัตรของขวัญ',
      },
    },
  },
};
