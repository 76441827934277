























































































































































































































import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import FileUploader from '@/components/FileUploader.vue';

class ProviderForm {
  public fullName: string;
  public email: string;
  public numberPhone: string;
  public category: string;
  public license: string;
  public licenseNumber: string;
  public refereeName: string;
  public refereeNumber: string;
  public platform: string;
  public resume: File;
  public cert: File;
  public otherUpload: File;
  constructor() {
    this.fullName = '';
    this.email = '';
    this.numberPhone = '';
    this.category = 'Psychiatrist';
    this.license = 'Thailand';
    this.licenseNumber = '';
    this.refereeName = '';
    this.refereeNumber = '';
    this.platform = 'Application';
    this.resume = null as any;
    this.cert = null as any;
    this.otherUpload = null as any;
  }
}

export default Vue.extend({
  data(): {
    providerForm: ProviderForm,
    error: number,
    submitting: boolean,
    submitSuccess: boolean,
  } {
    return {
      providerForm: new ProviderForm(),
      error: 0,
      submitting: false,
      submitSuccess : false,
    };
  },
  methods: {
    submit() {
      this.submitting = true;
      this.error = 0;
      const url: string = `${process.env.VUE_APP_API_LOCATION}/landing-join-provider`;
      const formData  = new FormData();

      formData.append('fullName', this.providerForm.fullName);
      formData.append('email', this.providerForm.email);
      formData.append('numberPhone', this.providerForm.numberPhone);
      formData.append('category', this.providerForm.category);
      formData.append('license', this.providerForm.license);
      formData.append('licenseNumber', this.providerForm.licenseNumber);
      formData.append('refereeName', this.providerForm.refereeName);
      formData.append('refereeNumber', this.providerForm.refereeNumber);
      formData.append('platform', this.providerForm.platform);
      if (this.providerForm.resume) {
        formData.append('resume', this.providerForm.resume);
      }
      if (this.providerForm.cert) {
        formData.append('cert', this.providerForm.cert);
      }
      if (this.providerForm.otherUpload) {
        formData.append('otherUpload', this.providerForm.otherUpload);
      }

      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      axios.post(url, formData, config)
        .then((response: any) => {
          this.submitting = false;
          this.providerForm = new ProviderForm();
          this.submitSuccess = true;
        }).catch((error: any) => {
        this.submitting = false;
        this.error = error.response.data.status_code;
      });
    },
    getError(): any {
      if (this.error === 422 ) {
        return this.$t(`joinCareer.error.${this.error}`);
      } else {
        return 'Something went wrong.';
      }
    },
  },
  components: {
    NavBar,
    Footer,
    FileUploader,
  },
});
