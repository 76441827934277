




















































































































































































































































































































































































































































































































import store from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import Modal from '@/components/Modal.vue';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import email from '@/customValidations/email';
import creditCard from '@/customValidations/creditCard';
import expireCard from '@/customValidations/expireCard';
import tel from '@/customValidations/tel';
import numberUtils from '../utils/number';
import 'aos/dist/aos.css'

declare var My2c2p: any;

export default Vue.extend({
  mixins: [validationMixin],
  data(): {
    currentStep: number,
    selectedTopics: any,
    providerType: string,
    fromFirstname: string,
    fromLastname: string,
    fromTel: string,
    fromEmail: string,
    toName: string,
    toTel: string,
    toEmail: string,
    message: string,
    cardHolderName: string,
    creditCard: string,
    expire: string,
    cvv: string,
    error: any,
    showVideoModal: boolean,
    webAppUrl: string,
    // loaded : boolean
  } {
    return {
      currentStep: 1,
      selectedTopics : [],
      providerType: '',
      fromFirstname: '',
      fromLastname: '',
      fromTel: '',
      fromEmail: '',
      toName: '',
      toTel: '',
      toEmail: '',
      message: '',
      cardHolderName: '',
      creditCard: '',
      expire: '',
      cvv: '',
      error: null,
      showVideoModal: false,
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
    };
  },
  validations: {
    fromFirstname: {
      required,
      maxLength: maxLength(100),
    },
    fromLastname: {
      required,
      maxLength: maxLength(100),
    },
    fromEmail: {
      required,
      email,
      maxLength: maxLength(100),
    },
    fromTel: {
      required,
      tel,
    },
    toName: {
      required,
      maxLength: maxLength(100),
    },
    toTel: {
      tel,
    },
    toEmail: {
      required,
      email,
      maxLength: maxLength(100),
    },
    message: {
      required,
      maxLength: maxLength(250),
    },
    cardHolderName: {
      required,
      maxLength: maxLength(250),
    },
    creditCard: {
      required,
      creditCard,
    },
    expire: {
      required,
      expireCard,
    },
    cvv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(3),
      numeric,
    },
  },
  computed: {
    numberUtils() {
      return numberUtils;
    },
    currentLang() {
      return (store.state as any).lang.language;
    },
    basePrice(): number {
      if (this.providerType === 'psychiatrist') {
        return 1500;
      }
      return 1000;
    },
    vat(): number {
      return Math.round(this.basePrice * 0.07);
    },
    priceWithVat(): number {
      return this.basePrice + this.vat;
    },
    creditCardNumber(): string {
      return this.creditCard.replace(/\s/g, '');
    },
    gatewayFee(): number {
      const fee = this.priceWithVat * 3.21 / 96.79;
      return Math.round(fee * 100) / 100;
    },
    total(): number {
      return this.gatewayFee + this.priceWithVat;
    },
    endpoint(): string {
      return `${process.env.VUE_APP_API_LOCATION}/voucher-orders`;
      // return 'https://api.ooca.co/api/voucher-orders';
    },
    senderName(): string {
      const name = this.fromFirstname + ' ' + this.fromLastname;
      return name.trim();
    },
    sender_tel(): string {
      return this.normalizedTel(this.fromTel);
    },
    receive_tel(): string {
      return this.normalizedTel(this.toTel);
    },
    expireMonth(): string {
      return this.expire.substring(0, 2);
    },
    expireYear(): string {
      return '20' + this.expire.substring(3);
    },
    isDetailFormInvalid(): boolean {
      return this.$v.fromFirstname.$invalid
          || this.$v.fromLastname.$invalid
          || this.$v.fromEmail.$invalid
          || this.$v.fromTel.$invalid
          || this.$v.toName.$invalid
          || this.$v.toTel.$invalid
          || this.$v.toEmail.$invalid
          || this.$v.message.$invalid;
    },
    isPaymentFormInvalid(): boolean {
      return this.$v.cardHolderName.$invalid
          || this.$v.creditCard.$invalid
          || this.$v.expire.$invalid
          || this.$v.cvv.$invalid;
    },
  },
  methods: {
    normalizedTel(phone: string) {
      let retval = phone || '';
      if (retval.substring(0, 1) === '0') {
        retval = `+66${retval.substring(1)}`;
      }
      return retval;
    },
    selectProviderType(provider: string) {
      this.providerType = provider;
      this.currentStep = 2;
    },
    finishDetailForm() {
      this.currentStep = 3;
    },
    errorForField(fieldName: string): string {
      const validatorObj = this.$v[fieldName];
      if (validatorObj) {
        const rulesArray = Object.keys(validatorObj).filter(
            (key) => key.indexOf('$') !== 0 && validatorObj[key] === false,
        );
        if (rulesArray.length > 0 && validatorObj.$error) {
          const rule = rulesArray[0];
          if (this.$te(`validation.voucher.${fieldName}.${rule}`)) {
            return this.$t(`validation.voucher.${fieldName}.${rule}`) as string;
          }
          return this.$t(`validation.voucher.${fieldName}.fallback`) as string;
        }
      }
      return '';
    },
  },
  watch: {
  },
  components: {
    NavBar,
    Footer,
    Modal,
  },
  mounted() {
    import('aos').then(
        AOS => AOS.init()
      );
    My2c2p.onSubmitForm('checkout-form', (errCode: any) => {
      if (errCode !== 0) {
        this.error = errCode;
        // console.log('error ' + errCode); /* eslint-disable-line no-console */
      }
    });
  },
});
