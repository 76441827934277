





















































import axios from 'axios';
import store from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default Vue.extend({
  data(): {
    voucherOrder: any,
    voucherId: string,
    error: any,
    webAppUrl: string,
  } {
    return {
      voucherOrder : null,
      voucherId: '',
      error: null,
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
    };
  },
  computed: {
    currentLang() {
      return (store.state as any).lang.language;
    },
    basePrice(): number {
      if (this.voucherOrder) {
        return this.voucherOrder.unit_value;
      }
      return 0;
    },
    vat(): number {
      return Math.round(this.basePrice * 0.07);
    },
    priceWithVat(): number {
      return this.basePrice + this.vat;
    },
    gatewayFee(): number {
      const fee = this.priceWithVat * 3.21 / 96.79;
      return Math.round(fee * 100) / 100;
    },
    total(): number {
      return this.gatewayFee + this.priceWithVat;
    },
    endpoint(): string {
      // return process.env.API_ENDPOINT;
      // return 'https://testapi.ooca.co/api/voucher-orders/' + this.voucherId;
      return 'https://ooca.test/api/voucher-orders/' + this.voucherId;
    },
  },
  methods: {
  },
  watch: {
  },
  components: {
    NavBar,
    Footer,
  },
  created() {
    this.voucherId =  this.$route.params.voucherid;
  },
  mounted() {
    // @TODO fetch voucher detail from API
    axios.get(this.endpoint)
      .then((response: any) => {
        if (response.status !== 200) {
          // error
        } else {
          this.voucherOrder = response.data.data;
        }
      })
      .catch((error: any) => {
        // error
      });
  },
});
